import { SurveyInfo } from "components/admin/results/interfaces";
import { Filter } from "components/filters/interfaces";
import { ConfidentialityResult } from "components/reports/heatmap/heatmap.init";
import { InsightLookdownByDemographic } from "api/rest/interfaces/InsightService.interface";

export interface InsightState {
    selectedSurvey: SurveyInfo;
    correlation: CorrelationInfo[];
    keyDriverList: number[];
    currentFilter: Filter;
    isFilterDialogOpen: boolean;
    confidentialityResult: ConfidentialityResult;
    responseCount: number;
    demographicField: DemographicInfo[];
    selectedOutcome: number;
    selectedKeyDriver: number;
    selectedDemographicField: string;
    previousPeriodLabels: string[];
    keyDriverScore: InsightLookdownByDemographic[];
    expandedKeyDriverSections: string[];
    selectedKeyDriverSection: string;
    showOrgOverall: boolean;
    customizedShowPP: boolean;
    showPreviousPeriod: boolean;
    selectedKeyItemId: number;
}

export interface InsightRestoreValue {
    surveySelected: SurveyInfo["id"];
    selectedOutcome: number;
    selectedKeyDriver: number;
    selectedDemographicField: string;
    showOrgOverall: boolean;
    customizedShowPP: boolean;
}

export interface DemographicInfo {
    id: string;
    name: string;
}

export interface CorrelationInfo {
    label: string;
    id: number;
    items: CorrelationItemInfo[];
    correlations: CorrelationDetail[];
}

export interface CorrelationItemInfo {
    label: string;
    id: number;
    correlations: CorrelationDetail[];
}

export interface CorrelationDetail {
    id: number;
    text: string;
    isSection: boolean;
    value: number;
    type: ProfileType;
}

export interface InsightComment {
    respondentId: number;
    surveyFieldId: number;
    start: number;
    length: number;
    text: string;
}

export enum ProfileType {
    None,
    Opportunity,
    Strength
}
