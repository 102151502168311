import React, { ChangeEvent, ReactElement, useState } from "react";
import { makeStyles } from "@mui/styles";
import { sortArray } from "core/helpers/SortArray.helper";
import { useLang, useLoading, useSurveys } from "core/hooks";
import { SurveyInfo } from "../interfaces";
import { isStringEmptyOrSpaces } from "core/helpers";
import { TargetSurvey, SurveyComparison } from "./interfaces";
import { initTargetSurvey } from "./init";
import { Button } from "lib/button";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Popover } from "lib/popover";
import { Typography } from "lib/typography";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";

const useStyles = makeStyles(() => ({
    popoverContainer: {
        width: 460,
        height: 340,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        boxSizing: "border-box"
    },
    labelContainer: {
        marginBottom: theme.spacing(3)
    },
    selectPrevSurvey: {
        width: 400,
        marginBottom: theme.spacing(3),
        "& div": {
            "&::after": {
                borderBottom: `2px solid ${theme.palette.primary.main}`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.palette.primary.main}`
        }
    },
    bold: {
        fontWeight: "bold"
    },
    helperText: {
        marginLeft: "0px !important",
        marginRight: "0px !important"
    }
}));

type Props = {
    anchorEl: Element | null;
    handleClosePopover: () => void;
    onAutoMap: (targetSurveyId: number, label: string) => void;
    selectedSurveyId: number;
    targetSurveySelectedId: number;
    allSurveyComparison: SurveyComparison[];
};

export const PPSecondMappingPopup = (props: Props): ReactElement => {
    const { lang } = useLang();
    const { surveys } = useSurveys();
    const { isLoading } = useLoading();
    const classes = useStyles();
    const [targetSurvey, setTargetSurvey] = useState<TargetSurvey>(initTargetSurvey);
    const [mappingLabel, setMappingLabel] = useState("");
    const [labelError, setLabelError] = useState<string | null>(null);

    const mapToTargetSurvey = (_surveys: SurveyInfo[], currentSurveyId: number): TargetSurvey[] => {
        const mappedSurveys = _surveys
            .filter((survey: SurveyInfo) => currentSurveyId !== survey.id && survey.active)
            .map((survey: SurveyInfo) => {
                return {
                    id: survey.id,
                    name: survey.translations[0].name
                };
            });
        return sortArray(mappedSurveys, "name", "asc");
    };

    const handleTargetSurveyChange = (value: AutocompleteValue | null): void => {
        const surveyFound = surveys.find((survey: SurveyInfo) => survey.id === value?.id);
        if (surveyFound) {
            setTargetSurvey(
                surveyFound
                    ? {
                          id: surveyFound.id,
                          name: surveyFound.translations[0].name
                      }
                    : initTargetSurvey
            );
        }
    };

    const handleMapLabelChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length > 7) {
            setLabelError(lang.mappingLabelDescription[0]);
            return;
        }
        if (props.allSurveyComparison.map(comparison => comparison.label).includes(event.target.value)) {
            setMappingLabel(event.target.value);
            setLabelError(lang.labelExists);
            return;
        }
        setMappingLabel(event.target.value);
        setLabelError(null);
    };

    const targetSurveys = mapToTargetSurvey(surveys, props.selectedSurveyId);

    const autoMapButtonDisabled = (): boolean => {
        if (!!labelError || isStringEmptyOrSpaces(mappingLabel) || targetSurvey.id === initTargetSurvey.id) return true;

        return isLoading;
    };

    const handleAutoMap = (): void => {
        props.onAutoMap(targetSurvey.id, mappingLabel);
    };

    return (
        <Popover
            open={props.anchorEl !== null}
            onClose={props.handleClosePopover}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
        >
            <div className={classes.popoverContainer}>
                <div className={classes.labelContainer}>
                    <Typography variant="body2">{lang.toContinueEnterInformationBelowAndAutomap}</Typography>
                </div>
                <Box width={400} mb={2}>
                    <Autocomplete
                        id="previous-period-second-select"
                        noOptionsText={lang.noOptionsAvailable}
                        options={targetSurveys.map(s => {
                            return {
                                id: s.id,
                                label: s.name
                            };
                        })}
                        onChange={handleTargetSurveyChange}
                        placeholder={lang.previousSurvey}
                        getOptionDisabled={(option): boolean => option.id === props.targetSurveySelectedId}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    <Typography variant="subtitle2">{lang.previousSurveyDescription}</Typography>
                </Box>
                <Box width={140}>
                    <TextField
                        data-testid="text-field-pp-add-second-mapped-label"
                        label={lang.mappingLabel}
                        name={lang.mappingLabel}
                        value={mappingLabel}
                        onChange={handleMapLabelChange}
                        error={!!labelError}
                        helperText={labelError ?? ""}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.helperText
                            }
                        }}
                    />
                </Box>
                {!labelError && (
                    <>
                        <Typography variant="subtitle2">{lang.mappingLabelDescription[0]}</Typography>
                        <Typography variant="subtitle2">{lang.mappingLabelDescription[1]}</Typography>
                    </>
                )}
                <Box mt={labelError ? 4 : 2}>
                    <Button
                        onClick={handleAutoMap}
                        disabled={autoMapButtonDisabled()}
                        data-testid="btn-pp-add-second-automap"
                    >
                        {lang.automap}
                    </Button>
                </Box>
            </div>
        </Popover>
    );
};
