import React, { ReactElement, useEffect } from "react";

import { DialogLayout } from "components/shared";
import { useLang } from "core/hooks";
import { useSingleDimensionWithItemsState } from "./useSingleDimensionWithItemsState";
import type { Props as PanelDialogProps } from "../PanelDialog";
import { Box as BoxType } from "../interfaces";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH, boxSize } from "../Dashboard";
import { SurveySection, SurveyQuestion } from "managerPortal/context/interfaces";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Checkbox } from "lib/checkbox";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { Select, SelectChangeEvent } from "lib/select-custom";

import { Typography } from "lib/typography";

type Props = {
    surveySections: SurveySection[];
    onClose: () => void;
    updatePanelItem: PanelDialogProps["updatePanelItem"];
    hasBenchmark: boolean;
    hasPreviousPeriod: boolean;
    box?: BoxType;
};

const availableSizes = [
    { id: "5 x 4", width: 5, height: 4, name: "5 x 4" },
    { id: "6 x 5", width: 6, height: 5, name: "6 x 5" }
];

const parseJSON = (str: string): Record<string, unknown> => {
    return JSON.parse(str);
};

const getInitSize = (box: BoxType): string => {
    const size = `${box.width} x ${box.height}` as string;
    return size;
};

const getInitHiddenField = (box: BoxType | undefined, attribute: string): boolean => {
    return box && box.props && parseJSON(box.props)[attribute] ? !!parseJSON(box.props)[attribute] : false;
};

export const SingleDimensionWithItems = ({
    onClose,
    updatePanelItem,
    hasBenchmark,
    hasPreviousPeriod,
    box,
    surveySections
}: Props): ReactElement => {
    const { lang } = useLang();
    const {
        isBenchmarkHidden,
        isPreviousPeriodHidden,
        setBenchmarkHidden,
        setPreviousPeriodHidden,
        size,
        setSize,
        selectedSingleDimensionWithItemsSize,
        setSelectedSingleDimensionWithItemsSize,
        singleDimensionWithItems,
        handleSingleDimensionWithItemsUpdate,
        titleTranslation,
        setTitleTranslation
    } = useSingleDimensionWithItemsState({
        initBenchmarkHidden: getInitHiddenField(box, "hideBMDelta"),
        initPreviousPeriodHidden: getInitHiddenField(box, "hidePPDelta"),
        initSize: availableSizes,
        initSingleDimensionWithItemsSize: box && getInitSize(box),
        initSingleDimensionWithItems: {
            id: -1,
            sectionField: ""
        },
        initTitleTranslation: box && box.translations
    });

    const filteredSurveySections = (): SurveySection[] => {
        return surveySections
            .map((section: SurveySection) => {
                return {
                    ...section,
                    questions: section.questions.filter((question: SurveyQuestion) => question.canCalculate === true)
                };
            })
            .filter((section: SurveySection) => section.questions.length);
    };

    const getWidth = (): number => {
        const sizeSelected = selectedSingleDimensionWithItemsSize.split("x")[0];
        return parseInt(sizeSelected.trim());
    };
    const getHeight = (): number => {
        const sizeSelected = selectedSingleDimensionWithItemsSize.split("x")[1];
        return parseInt(sizeSelected.trim());
    };

    const handleupdatePanelItem = (): void => {
        updatePanelItem(
            getWidth(),
            getHeight(),
            "singleDimensionWithItems",
            JSON.stringify({
                id: singleDimensionWithItems.id,
                hideBMDelta: isBenchmarkHidden,
                hidePPDelta: isPreviousPeriodHidden
            }),
            titleTranslation
        );
    };

    const handleOnFocus = (event: React.FocusEvent): void => {
        if (event.target) {
            const element = event.target as HTMLInputElement;
            element.select();
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "englishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "en" ? { languageCode: "en", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "frenchTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "fr" ? { languageCode: "fr", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "spanishTitle") {
            const updatedTitleTranslations = titleTranslation.map(t =>
                t.languageCode === "es" ? { languageCode: "es", name: value } : t
            );
            setTitleTranslation(updatedTitleTranslations);
            return;
        }
    };

    const handleSingleDimensionWithItemsFieldSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            clearSingleDimensionWithItemsField();
            return;
        }
        handleSingleDimensionWithItemsUpdate({
            ...singleDimensionWithItems,
            id: value.id,
            sectionField: value.label
        });
    };

    const getConfirmButtonDisableRule = (): boolean => {
        return singleDimensionWithItems.id === -1;
    };

    const clearSingleDimensionWithItemsField = (): void => {
        handleSingleDimensionWithItemsUpdate({
            ...singleDimensionWithItems,
            id: -1,
            sectionField: ""
        });
    };

    const onSelectedSingleDimensionWithItemsSize = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        if (value !== null && value !== undefined) {
            setSelectedSingleDimensionWithItemsSize(value as string);
        }
    };

    useEffect(() => {
        if (box) {
            setSize(
                availableSizes.filter(
                    item =>
                        item.width <= GRID_COL_LENGTH - box.left / boxSize &&
                        item.height <= GRID_ROW_LENGTH - box.top / boxSize
                )
            );
            if (box.props) {
                const properties = box.props;
                const sectionId = JSON.parse(properties).id;
                if (sectionId) {
                    const fieldName = filteredSurveySections().filter(
                        section => +section.sectionId.slice(1) === sectionId
                    )[0].title;
                    handleSingleDimensionWithItemsUpdate({
                        ...singleDimensionWithItems,
                        id: sectionId,
                        sectionField: fieldName
                    });
                }
            }
        }
    }, [box, open]);

    return (
        <DialogLayout
            title={lang.singleDimensionWithItems}
            onClose={onClose}
            open
            onClick={handleupdatePanelItem}
            buttonLabelAction={lang.ok}
            suppressContentPadding
            disableActionButton={getConfirmButtonDisableRule()}
        >
            <Box display="flex" flexDirection="column" pt={2} pb={2} pl={3} pr={3} gap={2}>
                <Select
                    data-testid="select-single-dimension-size"
                    items={size.map(s => {
                        return { value: s.id, name: s.name };
                    })}
                    onChange={onSelectedSingleDimensionWithItemsSize}
                    label={lang.size}
                    value={selectedSingleDimensionWithItemsSize}
                />
                <Autocomplete
                    id="dashboard-single-dimension-combo-box"
                    options={filteredSurveySections().map((section: SurveySection) => {
                        return {
                            id: +section.sectionId.slice(1),
                            label: section.title
                        };
                    })}
                    noOptionsText={lang.noOptionsAvailable}
                    value={{
                        id: singleDimensionWithItems.id,
                        label: singleDimensionWithItems.sectionField
                    }}
                    defaultValue={{
                        id: -1,
                        label: ""
                    }}
                    onChange={handleSingleDimensionWithItemsFieldSelected}
                    getOptionDisabled={(option): boolean => option.id === singleDimensionWithItems.id}
                    placeholder={lang.dimensions}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />

                {hasBenchmark && (
                    <Checkbox
                        checked={isBenchmarkHidden}
                        onChange={(e): void => setBenchmarkHidden(e.target.checked)}
                        label={lang.hideBenchmarkData}
                    />
                )}

                {hasPreviousPeriod && (
                    <Checkbox
                        checked={isPreviousPeriodHidden}
                        onChange={(e): void => setPreviousPeriodHidden(e.target.checked)}
                        label={lang.hidePriveousPeriodData}
                    />
                )}

                <Typography variant="body1">{lang.title}</Typography>
                <Box display="flex" alignItems="center">
                    <Typography width={100} variant="body1">
                        {lang.english}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"singleDimensionWithItems-englishTitle-TextField"}
                        name={"englishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "en")[0]?.name ?? ""}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography width={100} variant="body1">
                        {lang.spanish}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"singleDimensionWithItems-spanishTitle-TextField"}
                        name={"spanishTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "es")[0]?.name ?? ""}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography width={100} variant="body1">
                        {lang.french}
                    </Typography>
                    <TextField
                        onChange={handleChangeField}
                        onFocus={handleOnFocus}
                        data-testid={"singleDimensionWithItems-frenchTitle-TextField"}
                        name={"frenchTitle"}
                        value={titleTranslation.filter(t => t.languageCode === "fr")[0]?.name ?? ""}
                    />
                </Box>
            </Box>
        </DialogLayout>
    );
};
