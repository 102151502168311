import React, { ReactElement, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Popover from "@mui/material/Popover";
import { useLang, useToggle } from "core/hooks";
import { Checkbox } from "lib/checkbox";
import { Button } from "lib/button";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { Select, SelectChangeEvent } from "lib/select";
import { FilledInput } from "lib/filled-input";
import { MenuItem } from "lib/menu-item";
import { FormControl } from "lib/form-control";
import { InputLabel } from "lib/input-label";
import Filters from "components/filters/Filters";
import { Filter } from "components/filters/interfaces";
import { FilterMode } from "components/filters/enums";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { emptySurveyInfo } from "components/admin/results/init";
import { demographicFieldsAndValuesQuery } from "api/queries";
import { sortArray } from "core/helpers";
import { CompareGroup } from "components/reports/snapshot/Snapshot";

type Props = {
    showOrgOverall: boolean;
    showOtherComparison: boolean;
    anchorEl: Element | null;
    shouldEnableApply: boolean;
    columnLabel: string;
    customizedShowBenchmark: boolean;
    customizedShowPP: boolean;
    showBenchmarks: boolean;
    showPreviousPeriod: boolean;
    showFavorableUnfavorableOnly: boolean;
    handleClosePopover: () => void;
    setShowOrgOverall: () => void;
    setShowOtherComparison: () => void;
    applyComparison: () => void;
    handleColumnLabelOnChange: (e: string) => void;
    toggleCustomizedBenchmark: () => void;
    toggleCustomizedPP: () => void;
    setFavorableUnfavorableOnly: (value: boolean) => void;
    handleCmparisonGroupIdChange: (value: number) => void;
    handleCmparisonGroupFilterChange: (filter: Filter) => void;
    otherComparisonSurveyId: number;
    compareGroupIds: CompareGroup[];
    otherComparisonFilter: Filter;
};

export const SnapshotComparisonPopup = (props: Props): ReactElement => {
    const { lang, languageCode } = useLang();
    const {
        showOrgOverall,
        showOtherComparison,
        anchorEl,
        shouldEnableApply,
        columnLabel,
        customizedShowBenchmark,
        customizedShowPP,
        showBenchmarks,
        showPreviousPeriod,
        showFavorableUnfavorableOnly,
        handleClosePopover,
        setShowOrgOverall,
        setShowOtherComparison,
        applyComparison,
        handleColumnLabelOnChange,
        toggleCustomizedBenchmark,
        toggleCustomizedPP,
        setFavorableUnfavorableOnly,
        handleCmparisonGroupIdChange,
        handleCmparisonGroupFilterChange,
        otherComparisonSurveyId,
        compareGroupIds,
        otherComparisonFilter
    } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [showFavUnfavOnly, toggleFavUnfavOnly] = useToggle(showFavorableUnfavorableOnly);
    const [isComparisonFilterOpen, setIsComparisonFilterOpen] = useState<boolean>(false);
    const [comparisonContactFields, setComparisonContactFields] = useState<HrisFieldInfo[]>([]);
    let hrisList: HrisFieldInfo[] = [];

    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);
                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setComparisonContactFields(sortedDemographic);
            }
        }
    });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleColumnLabelOnChange(event.target.value.toString());
    };

    const onFavorableUnfavorableOnly = (): void => {
        setIsDirty(true);
        toggleFavUnfavOnly();
    };

    const handleApplyComparison = (): void => {
        setFavorableUnfavorableOnly(showFavUnfavOnly);
        applyComparison();
    };

    const handleSurveyCompareGroupSelected = (event: SelectChangeEvent<unknown>): void => {
        const compareGroupId = event.target.value as number;
        loadDemographics({ variables: { surveyId: compareGroupId, languageCode } }).then(() => {
            handleCmparisonGroupIdChange(compareGroupId);
        });
    };

    const handleCloseFilters = (): void => {
        setIsComparisonFilterOpen(false);
    };

    const openComparisonFilter = (): void => {
        setIsComparisonFilterOpen(true);
    };

    const handleFilterApplied = (filter: Filter): void => {
        handleCmparisonGroupFilterChange(filter);
    };

    useEffect(() => {
        if (otherComparisonSurveyId !== emptySurveyInfo.id) {
            loadDemographics({ variables: { surveyId: otherComparisonSurveyId, languageCode } });
        }
    }, []);

    return (
        <>
            <Popover
                open={anchorEl !== null}
                onClose={handleClosePopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                onKeyUp={e => {
                    if (e.key === "Enter" && (shouldEnableApply || isDirty)) {
                        handleApplyComparison();
                    }
                }}
            >
                <Box width="540px">
                    <Box p={3} display="flex" flexDirection="column" gap={"8px"}>
                        <Box display="flex">
                            <Box width="60%">
                                <Checkbox
                                    checked={showOrgOverall}
                                    onChange={setShowOrgOverall}
                                    data-testid="org-overall-checkbox"
                                    label={lang.overall}
                                />
                            </Box>
                            <Box width="40%">
                                {showBenchmarks && (
                                    <Checkbox
                                        checked={customizedShowBenchmark}
                                        onChange={toggleCustomizedBenchmark}
                                        data-testid="org-show-bm-checkbox"
                                        label={lang.benchmarkData}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box width="60%">
                                <Checkbox
                                    checked={showFavUnfavOnly}
                                    onChange={onFavorableUnfavorableOnly}
                                    data-testid="show-favorable-unfavorable-pp-checkbox"
                                    label={lang.showQuestionnaireItemOnly}
                                />
                            </Box>
                            <Box width="40%">
                                {showPreviousPeriod && (
                                    <Checkbox
                                        checked={customizedShowPP}
                                        onChange={toggleCustomizedPP}
                                        data-testid="org-show-pp-checkbox"
                                        label={lang.pPData}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box pl={3} pr={3} pt={2} pb={3} display="flex" flexDirection="column" gap={"8px"}>
                        <Checkbox
                            checked={showOtherComparison}
                            onChange={setShowOtherComparison}
                            data-testid="show-other"
                            label={lang.other}
                        />
                        <FormControl variant="filled">
                            <InputLabel shrink={true} id="mapToSurvey">
                                {lang.mappingToSurvey}
                            </InputLabel>
                            <Select
                                value={otherComparisonSurveyId}
                                onChange={handleSurveyCompareGroupSelected}
                                sx={{ width: 460, minWidth: 460, height: 52 }}
                                label={lang.mappingToSurvey}
                                disabled={!showOtherComparison}
                                labelId="mapToSurvey"
                                input={<FilledInput />}
                                color="primary"
                                data-testid={"select-compare-suvery"}
                            >
                                {compareGroupIds.map((comparegroups: CompareGroup) => (
                                    <MenuItem key={comparegroups.id} value={comparegroups.id}>
                                        {comparegroups.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box display="flex" alignItems={"center"} pt={2} pb={2} gap={4}>
                            <Button
                                style={{ width: 82, height: 42 }}
                                variant="outlined"
                                onClick={openComparisonFilter}
                                data-testid="btn-open-comparison-filter"
                                disabled={!showOtherComparison || otherComparisonSurveyId === emptySurveyInfo.id}
                                badgeValue={otherComparisonFilter.items.length}
                            >
                                {lang.filter}
                            </Button>
                            <TextField
                                sx={{ width: 200 }}
                                label={lang.columnLabel}
                                name={"columnLabel"}
                                value={columnLabel}
                                onChange={handleOnChange}
                                helperText={
                                    columnLabel.length > 6 ? lang.columnLabelErrorText : lang.columnLabelHelperText
                                }
                                disabled={!showOtherComparison}
                                error={columnLabel.length > 6}
                                data-testid="snapshot-select-column-label"
                            />
                        </Box>
                    </Box>
                    <Divider />
                    <Box height={50} display="flex" justifyContent="flex-end" alignItems="center" pr={2}>
                        <Button
                            variant="text"
                            onClick={handleApplyComparison}
                            data-testid="btn-snapshot-apply-comparison"
                            disabled={!shouldEnableApply && !isDirty}
                        >
                            {lang.ok}
                        </Button>
                    </Box>
                </Box>
            </Popover>
            {isComparisonFilterOpen && (
                <Filters
                    onCloseFilter={handleCloseFilters}
                    isOpen={isComparisonFilterOpen}
                    onFiltersApplied={handleFilterApplied}
                    currentFilter={otherComparisonFilter}
                    filterMode={FilterMode.snapshot}
                    surveyId={otherComparisonSurveyId}
                    contactFields={comparisonContactFields}
                    center
                />
            )}
        </>
    );
};
