import { IRouteProps } from "../interfaces";
import { UsersRoutes as UsersRoutesEnum } from "../enums";
import { UserInfo } from "../../components/admin/users/interface";
import EditUser from "../../components/admin/users/EditUser";
import AddUser from "../../components/admin/users/AddUser";

export const UsersRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        /**
         * Creating and Editing User need its own route to render its own sidebar
         * It can't be part of admin's route
         */
        {
            path: `/${UsersRoutesEnum.users}/${UsersRoutesEnum.editUser}/:id`,
            exact: true,
            label: UsersRoutesEnum.editUser,
            routeKey: UsersRoutesEnum.editUser,
            component: EditUser,
            isAccessGranted: user.isTalentMapAdmin || user.isSiteAdmin
        },
        {
            path: `/${UsersRoutesEnum.users}/${UsersRoutesEnum.addUser}`,
            exact: true,
            label: UsersRoutesEnum.addUser,
            routeKey: UsersRoutesEnum.addUser,
            component: AddUser,
            isAccessGranted: user.isTalentMapAdmin || user.isSiteAdmin
        }
    ];
};
