import React, { useState, ReactElement, useEffect } from "react";
import { useLang, useOrgChartService } from "core/hooks";
import { CreateTagDialog } from "./CreateTagDialog";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Tag } from "components/admin/tags/interface";
import { sortArray } from "core/helpers";
import { ComponentStatus } from "core/enums";
import Error500 from "components/errorPage/Error500";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { List, ListItemButton, ListItemText } from "lib/list";

const Tags = (): ReactElement => {
    const { lang } = useLang();
    const orgChartService = useOrgChartService();
    const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [status, setStatus] = useState<ComponentStatus>(ComponentStatus.idle);
    const [tags, setTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const [selectedTag, setSelectedTag] = useState<Tag>({ name: "", id: -1 });

    const sortTagsArray = <T extends Tag>(arrayToSort: T[]): T[] => sortArray(arrayToSort, "name", "asc");

    const addTagOnClick = (): void => {
        setCreateDialogOpen(true);
    };
    const deleteTagOnClick = (): void => {
        setDeleteDialogOpen(true);
    };
    const handleCreatetDialogClose = (): void => {
        setCreateDialogOpen(false);
    };
    const handleCreateTag = (tagName: string): void => {
        if (tagName) {
            orgChartService
                .addTags(tagName.trim())
                .then(res => {
                    if (res) {
                        getAllTags();
                        setCreateDialogOpen(false);
                    }
                })
                .catch(() => {
                    setStatus(ComponentStatus.error);
                });
        }
    };
    const handleDeleteDialogClose = (): void => {
        setDeleteDialogOpen(false);
    };
    const handleDeleteTag = (): void => {
        if (selectedTag && selectedTag.id !== -1) {
            orgChartService
                .deleteTag(selectedTag.id)
                .then(res => {
                    if (res) {
                        getAllTags();
                        setDeleteDialogOpen(false);
                    }
                })
                .catch(() => {
                    setStatus(ComponentStatus.error);
                });
        }
    };
    const handleTagSelected = (id: number, name: string): void => {
        const selectedTag = { name: name, id: id };
        setSelectedTag(selectedTag);
    };
    const getAllTags = async (): Promise<void> => {
        const tags = await orgChartService.getTags();
        setTags(tags);
        setSelectedTag({ name: "", id: -1 });
    };

    useEffect(() => {
        getAllTags();
    }, []);

    if (status === ComponentStatus.error) {
        return (
            <Box height="100%">
                <Error500 />
            </Box>
        );
    }
    return (
        <Box height="100%">
            <Box display="flex" justifyContent="space-between" padding={3}>
                <Typography variant="h6">{lang.benchmarkTags}</Typography>
                <Box display="flex" gap={2}>
                    <Button onClick={addTagOnClick} data-testid="btn-add-tag">
                        {lang.addTag}
                    </Button>
                    <Button onClick={deleteTagOnClick} data-testid="btn-delete-tag" disabled={selectedTag.id === -1}>
                        {lang.deleteTag}
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box padding={3}>
                <Typography fontWeight={400}>{lang.createdTags}</Typography>
            </Box>
            <List
                aria-label="tagList"
                sx={{
                    marginLeft: theme.spacing(1),
                    border: theme.border.main,
                    maxWidth: 360,
                    height: 690,
                    minHeight: 690,
                    maxHeight: 690,
                    padding: 0,
                    overflowY: "auto"
                }}
                id="tag-list"
            >
                {sortTagsArray(tags).map((tag: Tag) => (
                    <ListItemButton
                        key={tag.id}
                        selected={selectedTag.id === tag.id}
                        onClick={(): void => handleTagSelected(tag.id, tag.name)}
                        id={`tag-item-${tag.name}`}
                    >
                        <ListItemText primary={tag.name} sx={{ maxWidth: 300 }} />
                    </ListItemButton>
                ))}
            </List>
            {isCreateDialogOpen && (
                <CreateTagDialog
                    tags={tags}
                    dialogTitle={lang.createNewTag}
                    isOpen={isCreateDialogOpen}
                    onClose={handleCreatetDialogClose}
                    onSubmitCallback={handleCreateTag}
                />
            )}
            {isDeleteDialogOpen && (
                <ConfirmationDialog
                    open={isDeleteDialogOpen}
                    onCancelClicked={handleDeleteDialogClose}
                    onConfirmationClicked={handleDeleteTag}
                    title={`${lang.deleteTag} "${selectedTag.name}"`}
                    message={lang.thisActionWillPermanentlyRemoveTag}
                    confirmButtonText={lang.delete}
                    cancelButtonVariant="text"
                />
            )}
        </Box>
    );
};

export default Tags;
