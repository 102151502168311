import React, { ReactElement, FC, useState, useEffect, useRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import Highlighter from "react-highlight-words";
import { Clear } from "@mui/icons-material";

import { useLang, useInsightService, useLoading } from "core/hooks";
import SearchBar, { SearchBarHandle } from "managerPortal/components/shared/SearchBar";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import WordCloud, { WordDisplay, WordCloudHandle } from "components/reports/comment/word-cloud/word-cloud";
import { WordCloud as Word } from "components/reports/comment/interface";
import { CorrelationInfo, CorrelationItemInfo, DemographicInfo } from "components/reports/insight/interface";
import { Filter, FilterParameter } from "components/filters/interfaces";
import Loading from "components/shared/Loading";
import { InsightLookdownByDemographic } from "api/rest/interfaces/InsightService.interface";
import { lengthOfComments } from "components/reports/insight/Insight";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Typography } from "lib/typography";
import { Tab, Tabs } from "lib/tabs";
import { Divider } from "lib/divider";
import { List, ListItem, ListItemText } from "lib/list";
import { IconButton } from "lib/icon-button";
import { useUser } from "core/context/user/useUser";

export interface Comments {
    respondentId: number;
    surveyFieldId: number;
    text: string;
}

type Props = {
    keyDriverScoreThreshold: boolean;
    selectedKeyItemId: number;
    correlation: CorrelationInfo[];
    surveyId: number;
    currentFilter: Filter;
    selectedDemographicField: string;
    demographicField: DemographicInfo[];
    selectedKeyDriverSection: string;
    keyDriverScore: InsightLookdownByDemographic[];
    updateCommentLength: (count: number) => void;
};

export enum InsightCommentStatus {
    ready,
    workingInProgress
}

export const useStyles = makeStyles(() => ({
    commentContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    commentContainerWithLeftBorder: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        borderLeft: theme.border.main
    },
    insightCommentHeader: {
        display: "flex",
        height: 56,
        minHeight: 56,
        alignItems: "flex-end"
    },
    insightCommentList: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 58px)",
        overflowY: "auto"
    },
    commentList: {
        paddingTop: 0,
        paddingBottom: 0
    },
    commentItemText: {
        display: "flex",
        alignItems: "center",
        minHeightheight: 40,
        borderBottom: theme.border.main
    },
    insightCommentListHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: theme.border.main
    },
    commentCountHeader: {
        display: "flex",
        alignItems: "center",
        height: 50,
        paddingLeft: 24
    },
    searchBar: {
        width: 300,
        marginLeft: "auto",
        marginRight: 24,
        marginBottom: 6
    },
    deleteBtn: {
        display: "flex",
        paddingLeft: 8,
        paddingRight: 8
    },
    clearIconButton: {
        color: orangeColor,
        "& svg": {
            fontSize: 20
        }
    },
    insightCommentWordCloud: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        width: "100%",
        height: "calc(100% - 60px)",
        minHeight: "calc(100% - 60px)",
        justifyContent: "center"
    },
    wordCloud: {
        height: 300,
        width: 600
    },
    worldCloudDisplayChart: {
        position: "absolute",
        top: 10,
        right: 24
    },
    keyItemBelowThresholdText: {
        display: "flex",
        paddingLeft: 24,
        paddingTop: 16
    },
    highlightWord: {
        backgroundColor: "#7BEDA7"
    }
}));

export enum TabOptions {
    comments,
    wordCloud
}

const InsightComment: FC<Props> = (props): ReactElement => {
    const {
        selectedKeyItemId,
        correlation,
        surveyId,
        currentFilter,
        keyDriverScoreThreshold,
        demographicField,
        selectedDemographicField,
        selectedKeyDriverSection,
        keyDriverScore,
        updateCommentLength
    } = props;
    const classes = useStyles();
    const { lang, languageCode } = useLang();
    const { setLoading } = useLoading();
    const { user } = useUser();
    const insightService = useInsightService();
    const [comments, setComments] = useState<Comments[]>([]);
    const [tabValue, setTabValue] = useState<TabOptions>(
        user.isCommentViewer ? TabOptions.comments : TabOptions.wordCloud
    );
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [hoverWord, setHoverWord] = useState<WordDisplay>({ text: "", value: 0 });
    const [wordCloudRawData, setWordCloudRawData] = useState<Word[]>([]);
    const [status, setStatus] = useState<InsightCommentStatus>(InsightCommentStatus.ready);
    const [hasHiddenComment, setHasHiddenComment] = useState<boolean>(false);
    const searchBarRef = useRef<SearchBarHandle>(null);
    const wordCloudRef = useRef<WordCloudHandle>(null);
    const countOfWordCloud = 30;

    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: TabOptions): void => {
        setTabValue(newValue);
    };

    const onSearchTermChange = (searchString: string): void => {
        setSearchTerm(searchString);
        if (wordCloudRef && wordCloudRef.current) {
            wordCloudRef.current.onSearchWord(searchString);
        }
    };

    const fetchCommentDataAndWordCloud = useCallback(
        (selectedKeyItemFound: CorrelationItemInfo, shouldUpdateLoading: boolean) => {
            let currentFilterItems = currentFilter.items;
            if (
                selectedDemographicField !== "" &&
                selectedKeyDriverSection !== "" &&
                selectedKeyDriverSection !== keyDriverScore[0].text &&
                demographicField &&
                demographicField.length > 0
            ) {
                const selectedDemographicValue =
                    demographicField.find(d => d.id === selectedDemographicField)?.name ?? "";
                const newDemographicFilter = {
                    combinationOperator: "AND",
                    comparisonOperator: "EQUALS",
                    filterType: "ContactField",
                    field: { id: parseInt(selectedDemographicField), fieldName: selectedDemographicValue },
                    target: [{ id: selectedKeyDriverSection, text: selectedKeyDriverSection }],
                    order: currentFilter.items.length
                } as FilterParameter;
                currentFilterItems = [...currentFilter.items, newDemographicFilter];
            }
            insightService
                .getInsightComment(
                    surveyId,
                    0,
                    0,
                    currentFilterItems,
                    languageCode,
                    selectedKeyItemFound.label,
                    lengthOfComments,
                    selectedKeyItemFound.id
                )
                .then(data => {
                    const mappedComment = data.comments
                        ? data.comments.map(comment => ({
                              respondentId: comment.respondentId,
                              surveyFieldId: comment.surveyFieldId,
                              text: comment.text
                          }))
                        : [];
                    setComments(mappedComment);
                    setHasHiddenComment(data.hasHiddenComments);
                    setStatus(data.status);
                    updateCommentLength(mappedComment.length);
                    insightService
                        .getInsightWordCloud(
                            surveyId,
                            0,
                            0,
                            currentFilter.items,
                            languageCode,
                            selectedKeyItemFound.label,
                            countOfWordCloud,
                            lengthOfComments,
                            selectedKeyItemFound.id
                        )
                        .then(data => {
                            if (data.words && data.words.length > 0) {
                                setWordCloudRawData(data.words);
                            } else {
                                setWordCloudRawData([]);
                            }
                            setTimeout(() => {
                                if (wordCloudRef && wordCloudRef.current && searchTerm) {
                                    wordCloudRef.current.onSearchWord(searchTerm);
                                }
                            }, 1000);
                        });
                })
                .finally(() => {
                    if (shouldUpdateLoading) {
                        setLoading(false);
                    }
                });
        },
        [surveyId, selectedKeyItemId, selectedKeyDriverSection, currentFilter]
    );

    const handleResetComment = (): void => {
        if (selectedKeyItemId !== -1) {
            setLoading(true);
            const keyItems = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const selectedKeyItemFound = keyItems.find(i => i.id === selectedKeyItemId);
            if (selectedKeyItemFound) {
                insightService.resetInsightComment(selectedKeyItemFound.id).then(() => {
                    fetchCommentDataAndWordCloud(selectedKeyItemFound, true);
                });
            } else {
                setLoading(false);
            }
        }
    };

    const deleteFromCommentList = (respondentId: number, surveyFieldId: number): void => {
        if (selectedKeyItemId !== -1) {
            setLoading(true);
            const keyItems = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const selectedKeyItemFound = keyItems.find(i => i.id === selectedKeyItemId);
            if (selectedKeyItemFound) {
                insightService.hideInsightComment(selectedKeyItemFound.id, respondentId, surveyFieldId).then(() => {
                    fetchCommentDataAndWordCloud(selectedKeyItemFound, true);
                });
            } else {
                setLoading(false);
            }
        }
    };

    const handleSelectWordFromCloud = (word: string): void => {
        if (searchBarRef && searchBarRef.current) {
            searchBarRef.current.setInputField(word);
        }
        onSearchTermChange(word);
    };

    const handleHoverOverWordCloud = (text: string, value: number): void => {
        const hoverWord = { text, value };
        setHoverWord(hoverWord);
    };

    const handleMouseOutWordCloud = (): void => {
        const initHoverWord = { text: "", value: 0 };
        setHoverWord(initHoverWord);
    };

    const getHighlitedComment = (comment: string): ReactElement => {
        return (
            <>
                <Highlighter
                    highlightClassName={classes.highlightWord}
                    searchWords={searchTerm ? [searchTerm] : []}
                    caseSensitive={false}
                    autoEscape={true}
                    textToHighlight={comment}
                />
            </>
        );
    };

    useEffect(() => {
        if (selectedKeyItemId !== -1) {
            const keyItems = correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const selectedKeyItemFound = keyItems.find(i => i.id === selectedKeyItemId);
            if (selectedKeyItemFound) {
                fetchCommentDataAndWordCloud(selectedKeyItemFound, false);
            } else {
                setLoading(false);
            }
        }
    }, [surveyId, selectedKeyItemId, selectedKeyDriverSection, currentFilter]);

    if (!keyDriverScoreThreshold) {
        return (
            <div className={classes.commentContainer}>
                <Typography variant="body2" className={classes.keyItemBelowThresholdText}>
                    {lang.keyItemBelow30}
                </Typography>
            </div>
        );
    }

    if (status === InsightCommentStatus.workingInProgress) {
        return (
            <div className={classes.commentContainerWithLeftBorder}>
                <Loading styleProps={{ background: "white" }} zIndex={1000}>
                    <Typography>{lang.loadingComments}</Typography>
                </Loading>
            </div>
        );
    } else {
        return (
            <div className={classes.commentContainer}>
                <div className={classes.insightCommentHeader}>
                    <Tabs value={tabValue} aria-label="simpleTabs" textColor="secondary" onChange={handleTabChange}>
                        {user.isCommentViewer && <Tab label={lang.comments} data-testid="tab-handler-comments" />}
                        <Tab label={lang.wordCloud} data-testid="tab-handler-wordcloud" />
                    </Tabs>
                    <div className={classes.searchBar} data-testid="searchBar">
                        <SearchBar
                            ref={searchBarRef}
                            onDelayChange={(e: string): void => onSearchTermChange(e)}
                            labelText={lang.search}
                            delay={1000}
                            searchTerm={searchTerm}
                            size={"small"}
                        />
                    </div>
                </div>
                <Divider />
                {tabValue === TabOptions.comments && user.isCommentViewer && comments.length > 0 && (
                    <div className={classes.insightCommentListHeader}>
                        <Typography variant="h6" className={classes.commentCountHeader}>
                            {lang.commentSample}
                        </Typography>
                        <Box mr={3}>
                            <Button
                                variant="text"
                                onClick={handleResetComment}
                                data-testid="insight-comment-reset-btn"
                                disabled={!hasHiddenComment}
                            >
                                {lang.reset}
                            </Button>
                        </Box>
                    </div>
                )}
                {tabValue === TabOptions.comments && user.isCommentViewer && comments.length > 0 && (
                    <div className={classes.insightCommentList} data-testid={`insight-comment-list`}>
                        <List aria-label="commentList" className={classes.commentList}>
                            {comments.map((comment: Comments, index: number) => (
                                <ListItem key={index} className={classes.commentItemText}>
                                    <ListItemText
                                        primary={getHighlitedComment(comment.text)}
                                        style={{ paddingLeft: 8 }}
                                    />
                                    <div className={classes.deleteBtn} data-testid={`delete-btn-${index}`}>
                                        <IconButton
                                            size="small"
                                            className={classes.clearIconButton}
                                            aria-label={lang.delete}
                                            onClick={(): void =>
                                                deleteFromCommentList(comment.respondentId, comment.surveyFieldId)
                                            }
                                            color="primary"
                                        >
                                            <Clear />
                                        </IconButton>
                                    </div>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                )}
                {tabValue === TabOptions.comments && user.isCommentViewer && comments.length === 0 && (
                    <div className={classes.insightCommentList} data-testid={`insight-no-comment-list`}>
                        <Typography variant="body2" style={{ paddingTop: 24, paddingLeft: 18 }}>
                            {lang.noCommentInInsightPage}
                        </Typography>
                    </div>
                )}
                <div
                    className={classes.insightCommentWordCloud}
                    style={{ display: tabValue === TabOptions.wordCloud ? "flex" : "none" }}
                >
                    <div className={classes.worldCloudDisplayChart}>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                paddingRight: 8,
                                paddingTop: 10
                            }}
                        >
                            {`${hoverWord.text} ${hoverWord.value === 0 ? "" : `(${hoverWord.value})`}`}
                        </Typography>
                    </div>
                    <div className={classes.wordCloud} data-testid={`insight-word-cloud`}>
                        <WordCloud
                            ref={wordCloudRef}
                            rawData={wordCloudRawData}
                            selectWordFromCloud={handleSelectWordFromCloud}
                            hoverOverWordCloud={handleHoverOverWordCloud}
                            mouseOutWordCloud={handleMouseOutWordCloud}
                            extraActions={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default InsightComment;
