import React, { ReactElement, useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { makeStyles } from "@mui/styles";
import HelpIcon from "@mui/icons-material/Help";
import ClearIcon from "@mui/icons-material/Clear";
import { mdiPlus } from "@mdi/js";
import Papa from "papaparse";

import { useLang } from "core/hooks";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import DropArea from "components/shared/DropArea";
import { uploadEmpty, fileEmpty } from "components/admin/results/init";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import type { CommentSettingsHandle, SettingsState } from "./types";
import { Typography } from "lib/typography";
import { Button } from "lib/button";
import { Divider } from "lib/divider";
import { Box } from "lib/box";
import { IconButton, IconButtonV4 } from "lib/icon-button";
import { Tooltip } from "lib/tooltip";
import { ListItem, ListItemText } from "lib/list";

const useStyles = makeStyles(() => ({
    iconButton: {
        height: 30
    },
    stopWordListItemText: {
        display: "flex",
        alignItems: "center",
        height: 38,
        paddingRight: 10
    },
    clearIconButton: {
        height: 30,
        color: orangeColor
    }
}));

type Props = {
    openConfirmationDialog: () => void;
    hiddenWordValidationError: SettingsState["hiddenWordValidationError"];
    setHiddenWordValidationError: SettingsState["setHiddenWordValidationError"];
    hiddenWordsFileLoaded: SettingsState["hiddenWordsFileLoaded"];
    setHiddenWordsFileLoaded: SettingsState["setHiddenWordsFileLoaded"];
    hiddenWords: SettingsState["hiddenWords"];
    setHiddenWords: SettingsState["setHiddenWords"];
    newHiddenWord: SettingsState["newHiddenWord"];
    setNewHiddenWord: SettingsState["setNewHiddenWord"];
    selectedHiddenWord: SettingsState["selectedHiddenWord"];
    setSelectedHiddenWord: SettingsState["setSelectedHiddenWord"];
};

export const HiddenWordsPhrases = forwardRef<CommentSettingsHandle, Props>((props: Props, ref): ReactElement => {
    const { lang, languageCode } = useLang();
    const classes = useStyles();
    const {
        openConfirmationDialog,
        hiddenWordValidationError,
        setHiddenWordValidationError,
        hiddenWordsFileLoaded,
        setHiddenWordsFileLoaded,
        hiddenWords,
        setHiddenWords,
        newHiddenWord,
        setNewHiddenWord,
        selectedHiddenWord,
        setSelectedHiddenWord
    } = props;
    const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
    const autoCompleteRef = useRef<HTMLElement>(null);

    const handleFileDrop = (files: File[]): void => {
        const loadFile = { ...uploadEmpty, file: files[0], isLoaded: true };
        setHiddenWordsFileLoaded(loadFile);
        Papa.parse(files[0], {
            header: false,
            skipEmptyLines: true,
            complete: results => {
                const uploadedHiddenWordData = results.data as string[][];
                const uploadedHiddenWords = [
                    ...new Set(
                        uploadedHiddenWordData
                            .reduce((a, b) => a.concat(b), [])
                            .map(word => word.trim())
                            .filter(item => item)
                            .map(word => word.toLocaleLowerCase())
                    )
                ];
                setHiddenWords(uploadedHiddenWords);
            },
            error: () => {
                setHiddenWordsFileLoaded({
                    ...hiddenWordsFileLoaded,
                    errorMessage: lang.somethingWentWrong,
                    file: fileEmpty
                });
            }
        });
    };
    const handleRejectedFiles = (message: string): void => {
        setHiddenWordsFileLoaded({ ...hiddenWordsFileLoaded, errorMessage: message, file: fileEmpty });
    };
    const resetCommentSettings = (): void => {
        openConfirmationDialog();
    };

    useImperativeHandle(ref, () => ({
        resetImportedFile(): void {
            setHiddenWordsFileLoaded(uploadEmpty);
            setHiddenWords([]);
        }
    }));

    const stopWordSelected = (value: AutocompleteValue | null): void => {
        if (!value) return;
        setSelectedHiddenWord(value.label);
    };
    const handleAddHiddenWord = (): void => {
        if (newHiddenWord.trim() === "") return;
        if (hiddenWords.find(word => word.trim().toLowerCase() === newHiddenWord.trim().toLowerCase()) !== undefined) {
            setHiddenWordValidationError({
                key: "hiddenWords",
                message: lang.stopWordExist
            });
            setNewHiddenWord("");
            setAutocompleteOpen(false);
            return;
        }
        const newStopWords = [...hiddenWords, newHiddenWord.trim().toLowerCase()].sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setHiddenWords(newStopWords);
        if (autoCompleteRef && autoCompleteRef.current) {
            const ele = autoCompleteRef.current.getElementsByClassName(
                "MuiAutocomplete-clearIndicator"
            )[0] as HTMLButtonElement;
            if (ele) ele.click();
        }
        setAutocompleteOpen(false);
    };
    const deleteWordFromStopWordList = (word: string): void => {
        if (hiddenWords.find(w => w === word) !== undefined) {
            const newStopWords = [...hiddenWords].filter(w => w !== word);
            setHiddenWords(newStopWords);
        }
    };

    useEffect(() => {
        if (hiddenWordsFileLoaded.errorMessage) {
            setHiddenWordsFileLoaded({
                ...hiddenWordsFileLoaded,
                errorMessage: lang.errorMustUploadCSV,
                file: fileEmpty
            });
        }
    }, [languageCode]);

    return (
        <Box sx={{ overflow: "hidden" }}>
            <Box height={50} pl={2} pr={2} display="flex" justifyContent="space-between" alignItems={"center"}>
                <Typography variant="body2" fontWeight="500">
                    {lang.hiddenWordsAndPhrases}
                </Typography>
                <Button variant="text" onClick={resetCommentSettings} data-testid="btn-comment-setting-reset">
                    {lang.reset}
                </Button>
            </Box>
            <Divider />
            <Box display="flex" p={2} justifyContent="flex-start" alignItems="center" minWidth={"373px"}>
                <Box width={"285px"}>
                    <DropArea
                        fileDrop={handleFileDrop}
                        dataDropKey={"hiddenWords"}
                        label={lang.importHiddenWords}
                        width={260}
                        csvOnly
                        onRejectFiles={(): void => handleRejectedFiles(lang.stopWordsErrorMustUploadCSV)}
                    />
                    <Box mt={1} mb={1}>
                        <Typography
                            variant={!hiddenWordsFileLoaded.isLoaded ? "body2" : "body1"}
                            color={hiddenWordsFileLoaded.errorMessage ? "error" : "initial"}
                            data-testid={"comment-settings-import-file-info"}
                        >
                            {hiddenWordsFileLoaded.errorMessage
                                ? hiddenWordsFileLoaded.errorMessage
                                : !hiddenWordsFileLoaded.isLoaded
                                  ? lang.noFileAttached
                                  : hiddenWordsFileLoaded.file.name}
                        </Typography>
                    </Box>
                </Box>
                <Tooltip title={lang.hiddenWordsTooltip} placement="bottom" style={{ paddingLeft: "8px" }}>
                    <a
                        href={`https://docs.talentmap.com/knowledge-base/step-5-analyze-interpret-and-report/compass-reports/comments/comment-settings/hide-words-and-phrases/import-hidden-words-or-phrases`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IconButton size="small" className={classes.iconButton} aria-label={lang.help}>
                            <HelpIcon />
                        </IconButton>
                    </a>
                </Tooltip>
            </Box>
            <Box mb={2}>
                <Box display="flex" pl={2} alignItems="center">
                    <Autocomplete
                        sx={{ width: 285, minWidth: 285 }}
                        ref={autoCompleteRef}
                        id="comment-settings-combo-box"
                        value={selectedHiddenWord}
                        onChange={stopWordSelected}
                        onInputChange={(e, value, reason): void => {
                            if (hiddenWordValidationError.message !== "") {
                                setHiddenWordValidationError({ key: "", message: "" });
                            }
                            if (reason === "clear") {
                                setNewHiddenWord("");
                            }
                            if (reason === "input" && value) {
                                setNewHiddenWord(value);
                            }
                        }}
                        open={isAutocompleteOpen}
                        onOpen={(): void => {
                            setAutocompleteOpen(true);
                        }}
                        onClose={(): void => {
                            setAutocompleteOpen(false);
                        }}
                        autoSelect={false}
                        autoHighlight={false}
                        clearOnBlur={false}
                        onKeyDown={(e): void => {
                            if (e.key === "Enter") {
                                handleAddHiddenWord();
                            }
                        }}
                        noOptionsText={lang.noOptionsAvailable}
                        options={hiddenWords.map((s, i) => {
                            return { id: i, label: s };
                        })}
                        placeholder={lang.search}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    <Box sx={{ paddingLeft: "4px" }}>
                        <IconButtonV4
                            path={mdiPlus}
                            onClick={handleAddHiddenWord}
                            dataTestid="add-new-hidden-word"
                            disabled={false}
                        />
                    </Box>
                </Box>
                {hiddenWordValidationError.key === "hiddenWords" && (
                    <Typography variant="body2" style={{ paddingLeft: 16, color: "#f44336" }}>
                        {lang.hiddenWordExist}
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box
                sx={{
                    overflowY: "auto",
                    height: "252px",
                    scrollbarGutter: "stable"
                }}
                data-testid={"hidden-word-list"}
            >
                {hiddenWords
                    .sort((a, b) => {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    })
                    .map(word => (
                        <ListItem key={word} className={classes.stopWordListItemText} style={{ paddingRight: "8px" }}>
                            <ListItemText
                                primary={word}
                                primaryTypographyProps={{
                                    style: {
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: 290
                                    }
                                }}
                            />
                            <IconButton
                                size="small"
                                aria-label={lang.delete}
                                onClick={(): void => deleteWordFromStopWordList(word)}
                                color="primary"
                            >
                                <ClearIcon />
                            </IconButton>
                        </ListItem>
                    ))}
            </Box>
        </Box>
    );
});

HiddenWordsPhrases.displayName = "HiddenWordsPhrases";
