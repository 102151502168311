import { ReportRoutes as ReportRoutesEnum, MainRoutes } from "../enums";
import { IRouteProps } from "../interfaces";
import OrgChart from "../../components/reports/orgChart/OrgChart";
import ResponseRate from "../../components/reports/responseRate/ResponseRate";
import Snapshot from "../../components/reports/snapshot/Snapshot";
import Heatmap from "../../components/reports/heatmap/Heatmap";
import Comment from "../../components/reports/comment/Comment";
import Correlation from "../../components/reports/correlation/Correlation";
import UserDashboard from "../../components/reports/userDashboard/UserDashboard";
import { UserInfo } from "../../components/admin/users/interface";
import Insight from "../../components/reports/insight/Insight";

export const ReportRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.userDashboard}`,
            exact: true,
            routeKey: ReportRoutesEnum.userDashboard,
            isHiddenLink: true,
            component: UserDashboard,
            isAccessGranted: true
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.responseRate}`,
            exact: true,
            routeKey: ReportRoutesEnum.responseRate,
            isHiddenLink: true,
            component: ResponseRate,
            isAccessGranted: !user.isRestricted
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.snapshot}`,
            exact: true,
            routeKey: ReportRoutesEnum.snapshot,
            isHiddenLink: true,
            component: Snapshot,
            isAccessGranted: true
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.orgChart}`,
            exact: true,
            routeKey: ReportRoutesEnum.orgChart,
            isHiddenLink: true,
            component: OrgChart,
            isAccessGranted: !user.isRestricted
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.heatmap}`,
            exact: true,
            routeKey: ReportRoutesEnum.heatmap,
            isHiddenLink: true,
            component: Heatmap,
            isAccessGranted: true
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.comment}`,
            exact: true,
            routeKey: ReportRoutesEnum.comment,
            isHiddenLink: true,
            component: Comment,
            isAccessGranted: user.isCommentViewer
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.correlation}`,
            exact: true,
            routeKey: ReportRoutesEnum.correlation,
            isHiddenLink: true,
            component: Correlation,
            isAccessGranted: !user.isRestricted
        },
        {
            path: `/${MainRoutes.reports}/${ReportRoutesEnum.insight}`,
            exact: true,
            routeKey: ReportRoutesEnum.insight,
            isHiddenLink: true,
            component: Insight,
            isAccessGranted: user.isInsightsEnabled
        },
        {
            path: `/${MainRoutes.reports}`,
            routeKey: MainRoutes.reports,
            isHiddenLink: true,
            exact: true,
            isAccessGranted: true
        }
    ];
};
