import React, { ReactElement, useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Help, Clear } from "@mui/icons-material";
import { mdiPlus } from "@mdi/js";
import Papa from "papaparse";
import { FixedSizeList as VirtualizedList } from "react-window";
import { AutoSizer } from "react-virtualized";

import { useLang } from "core/hooks";
import { Button } from "lib/button";
import DropArea from "components/shared/DropArea";
import { uploadEmpty, fileEmpty } from "components/admin/results/init";
import { orangeColor } from "managerPortal/styles/GlobalStyles";
import { WordCloudSettings } from "../interface";
import { useAutoSizerStyles } from "core/styles";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Box } from "lib/box";
import { TextField } from "lib/text-field";
import { IconButton, IconButtonV4 } from "lib/icon-button";
import { theme } from "lib/theme";
import { ListItem, ListItemText } from "lib/list";
import { Popover } from "lib/popover";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { Divider } from "lib/divider";

const useStyles = makeStyles(() => ({
    popover: {
        "&.MuiPopover-root": {
            marginLeft: 4
        }
    },
    popover_row: {
        display: "flex",
        height: 50,
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        borderBottom: theme.border.main
    },
    stopWordsFileDrop: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 16,
        width: 300,
        maxWidth: 300,
        boxSizing: "border-box"
    },
    stopWordsFileDropWrap: {
        display: "flex",
        alignItems: "center",
        height: 118,
        borderBottom: theme.border.main,
        paddingRight: 16
    },
    iconButton: {
        height: 30
    },
    stopWordsHeader: {
        display: "flex",
        alignItems: "center",
        height: 50,
        justifyContent: "space-between"
    },
    root: {
        paddingLeft: 16,
        marginTop: 8,
        marginBottom: 8,
        "& .MuiInputLabel-outlined": {
            color: "black"
        }
    },
    stopWordsSearchBarWrap: {
        display: "flex",
        flexDirection: "column",
        borderBottom: theme.border.main,
        paddingBottom: theme.spacing(2)
    },
    stopWordsSearchBar: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    addIconButton: {
        marginTop: 0,
        paddingLeft: 8,
        height: 48
    },
    stopWordsList: {
        height: 268,
        overflowY: "auto"
    },
    clearIconButton: {
        height: 30,
        color: orangeColor
    }
}));

type IWordCloudProps = {
    anchorEl: Element | null;
    handleClosePopover: () => void;
    applySetting: (topWord: number, stopWords: string[]) => void;
    openConfirmationDialog: () => void;
    wordCloudSettings: WordCloudSettings;
    wordCloudTopWordsCount: number;
};

export type WordCloudHandle = {
    resetImportedFile: () => void;
    resetNumberOfTopWords: () => void;
};

export const WordCloudSettingPopup = forwardRef<WordCloudHandle, IWordCloudProps>(
    (props: IWordCloudProps, ref): ReactElement => {
        const { lang } = useLang();
        const classes = useStyles();
        const autoSizerClass = useAutoSizerStyles();
        const [preSelection, setPreSelection] = useState<{ topWords: number }>({
            topWords: props.wordCloudTopWordsCount
        });
        const [validationError, setValidationError] = useState({ key: "", message: "" });
        const [stopWordValidationError, setStopWordValidationError] = useState({ key: "", message: "" });
        const [stopWordsFileLoaded, setStopWordsFileLoaded] = useState(uploadEmpty);
        const [stopWords, setStopWords] = useState<string[]>([]);
        const [newStopWord, setNewStopWord] = useState<string>("");
        const [selectedStopWord, setSelectedStopWord] = useState<string>("");
        const [open, setOpen] = useState<boolean>(false);
        const autoCompleteRef = useRef<HTMLElement>(null);

        const validation = (fieldValue: number): boolean => {
            if (fieldValue <= 0 && Number.isInteger(fieldValue)) {
                setValidationError({
                    key: "topWords",
                    message: lang.minmumTopWordNumber
                });
                return false;
            } else if (fieldValue > 50 && Number.isInteger(fieldValue)) {
                setValidationError({
                    key: "topWords",
                    message: lang.maximumTopWordNumber
                });
                return false;
            } else {
                setValidationError({ key: "", message: "" });
            }
            return true;
        };
        const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const newNumber = +event.target.value;
            if (isNaN(newNumber)) return;
            setPreSelection({ topWords: newNumber });
            validation(newNumber);
        };
        const handleFileDrop = (files: File[]): void => {
            const loadFile = { ...uploadEmpty, file: files[0], isLoaded: true };
            setStopWordsFileLoaded(loadFile);
            Papa.parse(files[0], {
                header: false,
                skipEmptyLines: true,
                complete: results => {
                    const uploadedStopWordData = results.data as string[][];
                    const uploadedStopWords = [
                        ...new Set(
                            uploadedStopWordData
                                .reduce((a, b) => a.concat(b), [])
                                .map(word => word.trim())
                                .filter(item => item)
                                .concat(props.wordCloudSettings.defaultWordCloudStopWords)
                                .sort((a, b) => {
                                    return a.toLowerCase().localeCompare(b.toLowerCase());
                                })
                        )
                    ];
                    setStopWords(uploadedStopWords);
                },
                error: () => {
                    setStopWordsFileLoaded({
                        ...stopWordsFileLoaded,
                        errorMessage: lang.somethingWentWrong,
                        file: fileEmpty
                    });
                }
            });
        };
        const handleRejectedFiles = (message: string): void => {
            setStopWordsFileLoaded({ ...stopWordsFileLoaded, errorMessage: message, file: fileEmpty });
        };
        const resetStopWordsSettings = (): void => {
            props.openConfirmationDialog();
        };

        useImperativeHandle(ref, () => ({
            resetImportedFile(): void {
                setStopWordsFileLoaded(uploadEmpty);
            },
            resetNumberOfTopWords(): void {
                setPreSelection({ topWords: 50 });
                setStopWords(
                    props.wordCloudSettings.defaultWordCloudStopWords.sort((a, b) => {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    })
                );
            }
        }));

        const stopWordSelected = (value: AutocompleteValue | null): void => {
            if (!value) return;
            setSelectedStopWord(value.label);
        };

        const handleAddStopWord = (): void => {
            if (newStopWord.trim() === "") return;
            if (/\s/.test(newStopWord.trim())) {
                setStopWordValidationError({
                    key: "stopWords",
                    message: lang.maximumOneWord
                });
                setNewStopWord("");
                setOpen(false);
                return;
            }
            if (stopWords.find(word => word.trim().toLowerCase() === newStopWord.trim().toLowerCase()) !== undefined) {
                setStopWordValidationError({
                    key: "stopWords",
                    message: lang.stopWordExist
                });
                setNewStopWord("");
                setOpen(false);
                return;
            }
            const newStopWords = [...stopWords, newStopWord.trim().toLowerCase()].sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            setStopWords(newStopWords);
            if (autoCompleteRef && autoCompleteRef.current) {
                const ele = autoCompleteRef.current.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                )[0] as HTMLButtonElement;
                if (ele) ele.click();
            }
            setOpen(false);
        };
        const deleteWordFromStopWordList = (word: string): void => {
            if (stopWords.find(w => w === word) !== undefined) {
                const newStopWords = [...stopWords]
                    .filter(w => w !== word)
                    .sort((a, b) => {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    });
                setStopWords(newStopWords);
            }
        };

        const handleCancelOnClick = (): void => {
            if (
                props.wordCloudSettings &&
                props.wordCloudSettings.defaultWordCloudStopWords &&
                props.wordCloudSettings.defaultWordCloudStopWords.length > 0
            ) {
                setPreSelection({ topWords: props.wordCloudTopWordsCount });
                if (
                    props.wordCloudSettings.surveyWordCloudStopWords &&
                    props.wordCloudSettings.surveyWordCloudStopWords.length > 0
                ) {
                    setStopWords(
                        props.wordCloudSettings.surveyWordCloudStopWords.sort((a, b) => {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                    );
                } else if (
                    props.wordCloudSettings.defaultWordCloudStopWords &&
                    props.wordCloudSettings.defaultWordCloudStopWords.length > 0
                ) {
                    setStopWords(
                        props.wordCloudSettings.defaultWordCloudStopWords.sort((a, b) => {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                    );
                } else {
                    setStopWords([]);
                }
            }
            setValidationError({ key: "", message: "" });
            setStopWordValidationError({ key: "", message: "" });
            setNewStopWord("");
            setSelectedStopWord("");
            setStopWordsFileLoaded(uploadEmpty);
            props.handleClosePopover();
        };

        const stopwordsRow = ({ index }: { index: number }): ReactElement => {
            const word = stopWords[index];
            return (
                <ListItem style={{ display: "flex", alignItems: "center", height: 38, paddingRight: 8 }}>
                    <ListItemText
                        primary={word}
                        primaryTypographyProps={{
                            style: {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                width: 290
                            }
                        }}
                    />
                    <IconButton
                        size="small"
                        className={classes.clearIconButton}
                        aria-label={lang.delete}
                        onClick={(): void => deleteWordFromStopWordList(word)}
                        color="primary"
                    >
                        <Clear />
                    </IconButton>
                </ListItem>
            );
        };

        useEffect(() => {
            if (
                props.wordCloudSettings &&
                props.wordCloudSettings.defaultWordCloudStopWords &&
                props.wordCloudSettings.defaultWordCloudStopWords.length > 0
            ) {
                setPreSelection({ topWords: props.wordCloudTopWordsCount });
                if (
                    props.wordCloudSettings.surveyWordCloudStopWords &&
                    props.wordCloudSettings.surveyWordCloudStopWords.length > 0
                ) {
                    setStopWords(
                        props.wordCloudSettings.surveyWordCloudStopWords.sort((a, b) => {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                    );
                } else if (
                    props.wordCloudSettings.defaultWordCloudStopWords &&
                    props.wordCloudSettings.defaultWordCloudStopWords.length > 0
                ) {
                    setStopWords(
                        props.wordCloudSettings.defaultWordCloudStopWords.sort((a, b) => {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                    );
                } else {
                    setStopWords([]);
                }
            }
        }, [props.wordCloudSettings]);
        return (
            <Popover
                className={classes.popover}
                open={props.anchorEl !== null}
                onClose={props.handleClosePopover}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                onKeyUp={e => {
                    if (
                        e.key === "Enter" &&
                        validationError.key === "" &&
                        stopWordsFileLoaded.errorMessage === undefined
                    ) {
                        props.applySetting(preSelection.topWords, stopWords);
                    }
                }}
            >
                <Box sx={{ height: 672, overflowY: "hidden" }}>
                    <Box>
                        <div className={classes.popover_row}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    paddingLeft: 16,
                                    paddingTop: 8,
                                    paddingBottom: 8
                                }}
                            >
                                {lang.wordCloudSettings}
                            </Typography>
                        </div>
                        <Box display="flex" gap={1} pl={2} pt={1} height={62}>
                            <Typography alignSelf="flex-end">{lang.showTopWordsPrefix}</Typography>
                            <Box width={120}>
                                <TextField
                                    onChange={handleChangeField}
                                    data-testid="text-field-word-cloud-top-words"
                                    label={lang.number}
                                    name={"topWords"}
                                    value={preSelection.topWords}
                                    error={validationError.key === "topWords"}
                                    helperText={validationError.key === "topWords" ? validationError.message : ""}
                                />
                            </Box>
                            <Typography alignSelf="flex-end">{lang.showTopWordsSuffix}</Typography>
                        </Box>
                        <div className={classes.stopWordsFileDropWrap}>
                            <div className={classes.stopWordsFileDrop}>
                                <DropArea
                                    fileDrop={handleFileDrop}
                                    dataDropKey={"stopWords"}
                                    label={lang.importStopWords}
                                    width={260}
                                    csvOnly
                                    onRejectFiles={(): void => handleRejectedFiles(lang.stopWordsErrorMustUploadCSV)}
                                />
                                <Typography
                                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                                    variant={!stopWordsFileLoaded.isLoaded ? "body2" : "body1"}
                                    color={stopWordsFileLoaded.errorMessage ? "error" : "initial"}
                                    data-testid={"word-cloud-settings-import-file-info"}
                                >
                                    {stopWordsFileLoaded.errorMessage
                                        ? stopWordsFileLoaded.errorMessage
                                        : !stopWordsFileLoaded.isLoaded
                                          ? lang.noFileAttached
                                          : stopWordsFileLoaded.file.name}
                                </Typography>
                            </div>
                            <Tooltip title={lang.stopWordsTooltip} placement="bottom">
                                <a
                                    href={`https://docs.talentmap.com/knowledge-base/step-5-analyze-interpret-and-report/compass-reports/comments/word-cloud/import-stop-words`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IconButton size="small" className={classes.iconButton} aria-label={lang.help}>
                                        <Help />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </div>
                        <div className={classes.stopWordsHeader}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    paddingLeft: 16,
                                    paddingTop: 8,
                                    paddingBottom: 8
                                }}
                            >
                                {lang.stopWords}
                            </Typography>
                            <Box p={1}>
                                <Button
                                    variant="text"
                                    onClick={resetStopWordsSettings}
                                    data-testid="btn-comment-word-cloud-setting-reset"
                                >
                                    {lang.reset}
                                </Button>
                            </Box>
                        </div>
                        <div className={classes.stopWordsSearchBarWrap}>
                            <div className={classes.stopWordsSearchBar}>
                                <Autocomplete
                                    ref={autoCompleteRef}
                                    id="comment-word-cloud-settings-combo-box"
                                    value={selectedStopWord}
                                    onChange={stopWordSelected}
                                    open={open}
                                    onOpen={(): void => {
                                        setOpen(true);
                                    }}
                                    onClose={(): void => {
                                        setOpen(false);
                                    }}
                                    onInputChange={(e, value, reason): void => {
                                        if (stopWordValidationError.message !== "") {
                                            setStopWordValidationError({ key: "", message: "" });
                                        }
                                        if (reason === "clear") {
                                            setNewStopWord("");
                                        } else {
                                            if (value) {
                                                setNewStopWord(value);
                                            }
                                        }
                                    }}
                                    onKeyDown={(e): void => {
                                        if (e.key === "Enter") {
                                            handleAddStopWord();
                                        }
                                    }}
                                    autoSelect={false}
                                    autoHighlight={false}
                                    clearOnBlur={false}
                                    noOptionsText={lang.noOptionsAvailable}
                                    options={stopWords.map((s, i) => {
                                        return { id: i, label: s };
                                    })}
                                    placeholder={lang.search}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                                <div className={classes.addIconButton}>
                                    <IconButtonV4
                                        path={mdiPlus}
                                        onClick={handleAddStopWord}
                                        dataTestid="add-new-stop-word"
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            {stopWordValidationError.key === "stopWords" && (
                                <Typography variant="body2" style={{ paddingLeft: 16, color: "#f44336" }}>
                                    {stopWordValidationError.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.stopWordsList} data-testid={"stop-word-list"}>
                            <AutoSizer className={autoSizerClass.autoSizer}>
                                {({ height, width }): JSX.Element => (
                                    <VirtualizedList
                                        height={height}
                                        width={width}
                                        itemCount={stopWords.length}
                                        itemSize={38}
                                    >
                                        {stopwordsRow}
                                    </VirtualizedList>
                                )}
                            </AutoSizer>
                        </div>
                    </Box>
                    <Divider />
                    <Box display="flex" alignItems="center" justifyContent="flex-end" height={50} pr={3} gap={2}>
                        <Button
                            variant="text"
                            onClick={(): void => {
                                props.applySetting(preSelection.topWords, stopWords);
                            }}
                            data-testid="btn-comment-word-cloud-setting-apply"
                            disabled={validationError.key !== "" || stopWordsFileLoaded.errorMessage !== undefined}
                        >
                            {lang.ok}
                        </Button>
                        <Button
                            variant="text"
                            onClick={handleCancelOnClick}
                            data-testid="btn-comment-word-cloud-setting-cancel"
                        >
                            {lang.cancel}
                        </Button>
                    </Box>
                </Box>
            </Popover>
        );
    }
);

WordCloudSettingPopup.displayName = "WordCloudSettingPopup";
