import React, { PropsWithChildren, ReactElement } from "react";
import { useUser } from "core/context/user/useUser";
import Search from "../../shared/Search";
import { ButtonLink } from "lib/button-link";
import { Button } from "lib/button";
import { Box } from "lib/box";

type Props = {
    onSearchChange: (str: string) => void;
    searchTerm: string;
    showButton: boolean;
    buttonLink?: string;
    buttonLabel?: string;
    addResultLabel?: string;
    addResultDisabled?: boolean;
    buttonOnClick?: () => void;
    addResultOnClick?: () => void;
    addUserLabel?: string;
    addUserOnClick?: () => void;
};

const AdminLayout = (props: PropsWithChildren<Props>): ReactElement => {
    const { user } = useUser();

    const handleSearchChange = (searchString: string): void => {
        props.onSearchChange(searchString);
    };

    const buttonOnClick = (): void => {
        props.buttonOnClick && props.buttonOnClick();
    };

    const addResultOnClick = (): void => {
        props.addResultOnClick && props.addResultOnClick();
    };

    return (
        <Box
            height={"100%"}
            display={"grid"}
            gridTemplateRows={"104px calc(100% - 104px)"}
            gridTemplateAreas={`
            "adminHeader"
            "adminContent"
        `}
        >
            <Box gridArea={"adminHeader"} width={"100%"}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={"24px 34px 24px 24px"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Search onSearchChange={handleSearchChange} searchTerm={props.searchTerm} width={280} />
                        {props.buttonLink && props.addUserLabel && (
                            <ButtonLink
                                variant="text"
                                style={{ marginLeft: "16px" }}
                                link={props.buttonLink}
                                data-testid={props.addUserLabel.replace(" ", "-")}
                            >
                                {props.addUserLabel}
                            </ButtonLink>
                        )}
                    </Box>
                    {props.buttonLink && props.buttonLabel ? (
                        <ButtonLink link={props.buttonLink} data-testid={props.buttonLabel.replace(" ", "-")}>
                            {props.buttonLabel}
                        </ButtonLink>
                    ) : props.addResultLabel && props.buttonLabel && user.isTalentMapAdmin ? (
                        <Box display="flex" gap={2}>
                            <Button
                                onClick={addResultOnClick}
                                disabled={props.addResultDisabled}
                                data-testid="btn-add-result"
                            >
                                {props.addResultLabel}
                            </Button>

                            <Button onClick={buttonOnClick} data-testid="btn-import">
                                {props.buttonLabel}
                            </Button>
                        </Box>
                    ) : props.buttonLabel && props.showButton ? (
                        <Button onClick={buttonOnClick} data-testid={props.buttonLabel}>
                            {props.buttonLabel}
                        </Button>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            <Box sx={{ gridArea: "adminContent", overflowX: "auto", overflowY: "hidden" }}>{props.children}</Box>
        </Box>
    );
};

export default AdminLayout;
