import React, { useReducer, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { createOrgMutation } from "api/mutations";
import {
    OrganizationDetailState,
    OrganizationInfo as IOrganizationInfo,
    OrganizationInput,
    OrganizationLogo
} from "./interfaces";
import { emptyOrganization, emptyOrgInput } from "./initialVariables";
import SidebarHandleState from "../../shared/SidebarHandleState";
import { useLang, useSnackbar } from "core/hooks";
import { OrganizationDetailReducer } from "./reducers";
import { OrganizationDetailActions } from "./actions";
import OrganizationInfo from "./OrganizationInfo";
import { formValidation } from "./helpers";
import { Tag } from "components/admin/tags/interface";
import { capitalize } from "core/helpers";
import { Typography } from "lib/typography";
import { useOrganization } from "core/context/organization/useOrganization";

const init = (): OrganizationDetailState => {
    return {
        organization: { ...emptyOrganization },
        selectedSidebarItem: "information",
        validation: { message: "", key: "" },
        createdTags: []
    };
};

const AddOrg = (): ReactElement => {
    const { lang } = useLang();
    const { organizations, addOrganization } = useOrganization();
    const [state, dispatch] = useReducer(OrganizationDetailReducer, null, init);
    const sidebarItems = [{ id: "information", title: lang.information }];
    const redirectURL = "/admin/organizations";
    const history = useHistory();
    const { setMessage } = useSnackbar();

    const [createOrganization] = useMutation(createOrgMutation, {
        onCompleted: data => {
            const addNewOrg = { ...state.organization, id: +data.createOrganization };
            addOrganization(addNewOrg);
            history.push(redirectURL);
        }
    });

    const validation = (organization: IOrganizationInfo): boolean => {
        const valid = formValidation(organization, organizations, lang);
        if (!valid.isValid) {
            dispatch({
                type: OrganizationDetailActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: valid.errorMessage, key: valid.validationKey }
                }
            });
            return false;
        }
        return true;
    };

    const handleSave = (organization: IOrganizationInfo, isNewFile: boolean): void => {
        const orgInput: OrganizationInput = { ...emptyOrgInput };

        orgInput.active = true;
        orgInput.name = organization.name;
        orgInput.isDashboardEnabled = organization.isDashboardEnabled;
        orgInput.isAIEnabled = organization.isAIEnabled;
        orgInput.thirdPartyLogoBase64 = organization.thirdPartyLogoBase64;
        orgInput.thirdPartyLogoFileName = organization.thirdPartyLogoFileName;

        if (organization.organizationLogo && isNewFile) {
            if (organization.organizationLogo.logoUrl !== "") {
                orgInput.encodedLogo = organization.organizationLogo.logoUrl;
            }
            if (organization.organizationLogo.originalFileName !== "") {
                orgInput.encodedLogoFilename = organization.organizationLogo.originalFileName;
            }
        }

        const queryOptions = {
            variables: { organization: { ...orgInput, tagIds: state.createdTags.map(t => t.id) } }
        };

        createOrganization(queryOptions);
    };

    const handleSaveButton = (): void => {
        if (!validation(state.organization)) {
            return;
        }
        handleSave(state.organization, true);
    };

    /**
     * ONLY SAVE AUTOMATICALLY WHEN ITS IN EDITING MODE
     */
    const handleChange = (
        key: keyof IOrganizationInfo,
        newValue: string | number | boolean | OrganizationLogo,
        shouldSave: boolean
    ): void => {
        const updateOrg: IOrganizationInfo = {
            ...state.organization,
            [key]: newValue
        };

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization: updateOrg }
        });
        dispatch({
            type: OrganizationDetailActions.SET_VALIDATION_ERROR,
            payload: { validation: { message: "", key: "" } }
        });

        if (shouldSave) {
            if (validation(updateOrg)) handleSave(updateOrg, false);
        }
    };

    const handleDeactivateOrg = (): void => {
        const updateOrg = { ...state.organization };
        if (!validation(updateOrg)) {
            return;
        }

        updateOrg.active = !updateOrg.active;
        let snackbarMessage = lang.organizationHasBeenReactivated;
        if (!updateOrg.active) {
            snackbarMessage = lang.organizationHasBeenDeactivated;
        }

        setMessage(snackbarMessage);
        handleSave(updateOrg, false);
    };

    const handleSidebarClick = (meuItem: string): void => {
        dispatch({
            type: OrganizationDetailActions.UPDATE_SELECTED_SIDEBAR,
            payload: { selectedSidebarItem: meuItem }
        });
    };

    const handleUploadLogo = (encodedLogo: string, file: File): void => {
        const organization = { ...state.organization };

        organization.organizationLogo = {
            logoUrl: encodedLogo,
            originalFileName: file.name
        };

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization }
        });
    };
    const handleUploadThirdPartyLogo = (encodedLogo: string, file: File): void => {
        const organization = { ...state.organization };

        organization.thirdPartyLogoBase64 = encodedLogo;
        organization.thirdPartyLogoFileName = file.name;

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization }
        });
    };

    const handleDeleteLogo = (): void => {
        const org = { ...state.organization };

        org.organizationLogo = emptyOrganization.organizationLogo;

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization: org }
        });
    };

    const handleDeleteThirdPartyLogo = (): void => {
        const org = { ...state.organization };

        org.thirdPartyLogoBase64 = "";
        org.thirdPartyLogoFileName = "";

        dispatch({
            type: OrganizationDetailActions.UPDATE_ORG,
            payload: { organization: org }
        });
    };

    const handleAddTag = (tag: Tag): void => {
        dispatch({
            type: OrganizationDetailActions.ADD_TAG,
            payload: { tag: tag }
        });
    };

    const handleDeleteTag = (tag: Tag): void => {
        dispatch({
            type: OrganizationDetailActions.DELETE_TAG,
            payload: { tag: tag }
        });
    };

    return (
        <Box height={"100%"}>
            <Box
                height={"56px"}
                width={"100%"}
                pl={3}
                display={"flex"}
                alignItems={"center"}
                borderBottom={theme.border.main}
            >
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} mr={2}>
                    <Typography variant="h6">{capitalize(lang.addOrganization)}</Typography>
                </Box>
            </Box>
            <Box display={"flex"} height={"calc(100% - 56px)"}>
                <SidebarHandleState
                    itemList={sidebarItems}
                    onClick={handleSidebarClick}
                    itemSelected={state.selectedSidebarItem}
                />
                {state.selectedSidebarItem === "information" && (
                    <OrganizationInfo
                        organization={state.organization}
                        onChangeField={handleChange}
                        isNewOrganization={true}
                        onDeactivateOrg={handleDeactivateOrg}
                        validation={state.validation}
                        onUploadLogo={handleUploadLogo}
                        onDeleteLogo={handleDeleteLogo}
                        onThirdPartyUploadLogo={handleUploadThirdPartyLogo}
                        onDeleteThirdPartyLogo={handleDeleteThirdPartyLogo}
                        addCreatedTag={handleAddTag}
                        deleteCreatedTag={handleDeleteTag}
                        editMode={false}
                        redirectURL={redirectURL}
                        handleSaveButton={handleSaveButton}
                    />
                )}
            </Box>
        </Box>
    );
};

export default AddOrg;
