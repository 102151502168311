import React, { ReactElement, useMemo } from "react";
import saveAs from "file-saver";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { mapToTargetSurvey } from "core/helpers";
import { useReviewBenchmarkState } from "./useReviewBenchmarkState";
import { useLang, useSurveys, useAdminService, useSnackbar, useLoading } from "core/hooks";
import { emptySurveyInfo } from "components/admin/results/init";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Card } from "lib/card-custom";
import { Box } from "lib/box";
import { Button } from "lib/button";
import { Typography } from "lib/typography";
import { Popover } from "lib/popover";

type Props = {
    surveyId: number;
};

export const ReviewBenchmark = ({ surveyId }: Props): ReactElement => {
    const { lang } = useLang();
    const { surveys } = useSurveys();
    const { setLoading } = useLoading();
    const { setMessage } = useSnackbar();
    const adminService = useAdminService();
    const [previousPeriod1, setPreviousPeriod1] = useReviewBenchmarkState();
    const [previousPeriod2, setPreviousPeriod2] = useReviewBenchmarkState();

    const targetSurveys = useMemo(() => mapToTargetSurvey(surveys, surveyId, true), [surveys, surveyId]);

    const getPreviousPeriod = (selected: number | undefined) => {
        const selectedSurvey = surveys.find(s => s.id === selected);
        if (!selected || !selectedSurvey) {
            return {
                id: -1,
                label: ""
            };
        }
        return {
            id: selectedSurvey.id,
            label: selectedSurvey.translations[0].name
        };
    };

    const handleOnChange = (value: AutocompleteValue | null, selectorId: string): void => {
        if (value !== null) {
            const selectedSurvey = surveys.find(s => s.id === value.id);

            if (selectedSurvey) {
                if (selectorId === "previousPeriod1") {
                    setPreviousPeriod1(selectedSurvey.id);
                }
                if (selectorId === "previousPeriod2") {
                    setPreviousPeriod2(selectedSurvey.id);
                }
            }
        }
    };

    const clearState = (): void => {
        setPreviousPeriod1(undefined);
        setPreviousPeriod2(undefined);
    };

    const handleConfirm = (): void => {
        const prevSurveyIds: number[] = [previousPeriod1 || 0, previousPeriod2 || 0].filter(s => s !== 0);
        setLoading(true);
        clearState();
        adminService
            .automapReview(surveyId, prevSurveyIds)
            .then((blob: unknown) => {
                saveAs(blob as Blob, "review-previous-period" + ".xlsx");
                setLoading(false);
            })
            .catch(() => {
                setMessage(lang.somethingWentWrong);
                setLoading(false);
            });
    };

    return (
        <PopupState variant="popover">
            {(popupState): ReactElement => (
                <div
                    onKeyUp={e => {
                        if (e.key === "Enter" && (previousPeriod1 !== undefined || previousPeriod2 !== undefined)) {
                            handleConfirm();
                            popupState.setOpen(false);
                        }
                    }}
                >
                    <Button
                        data-testid={"reports-response-rate-review-btn"}
                        disabled={surveyId === emptySurveyInfo.id}
                        variant="outlined"
                        style={{ marginRight: 12 }}
                        {...bindTrigger(popupState)}
                    >
                        {lang.review}
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        <Card
                            height={400}
                            width={350}
                            title="Review Benchmark Mapping"
                            onCancelAction={(): void => {
                                clearState();
                                popupState.setOpen(false);
                            }}
                            onConfirmAction={(): void => {
                                handleConfirm();
                                popupState.setOpen(false);
                            }}
                            isConfirmDisabled={previousPeriod1 == undefined && previousPeriod2 == undefined}
                        >
                            <Box pl={2} pr={2} pt={2}>
                                <Box>
                                    <Typography variant="subtitle1">{`${lang.previousPeriod} 1`}</Typography>
                                    <Autocomplete
                                        id="previousPeriod1"
                                        value={getPreviousPeriod(previousPeriod1)}
                                        options={targetSurveys
                                            .filter(s => s.id !== previousPeriod2)
                                            .map(s => {
                                                return {
                                                    id: s.id,
                                                    label: s.name
                                                };
                                            })}
                                        noOptionsText={lang.noOptionsAvailable}
                                        onChange={value => handleOnChange(value, "previousPeriod1")}
                                        placeholder={lang.previousPeriod}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="subtitle1">{`${lang.previousPeriod} 2`}</Typography>
                                    <Autocomplete
                                        id="previousPeriod2"
                                        value={getPreviousPeriod(previousPeriod2)}
                                        options={targetSurveys
                                            .filter(s => s.id !== previousPeriod1)
                                            .map(s => {
                                                return {
                                                    id: s.id,
                                                    label: s.name
                                                };
                                            })}
                                        noOptionsText={lang.noOptionsAvailable}
                                        onChange={value => handleOnChange(value, "previousPeriod2")}
                                        placeholder={lang.previousPeriod}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Box>
                            </Box>
                        </Card>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
};
