import React, { ReactElement, useState, useEffect } from "react";
import { theme } from "lib/theme";
import saveAs from "file-saver";
import Search from "components/shared/Search";
import { useLang, useOrgChartService, useLoading } from "core/hooks";
import EmptyPage from "components/shared/EmptyPage";
import EmptyResults from "assets/images/emptyPages/ReportingEmptyState.svg";
import { Translation } from "core/languages/interfaces";
import { Column } from "components/shared/infiniteTable/interfaces";
import { DataKey, TableSort, CellType } from "../../shared/infiniteTable/enums";
import InfiniteTable from "components/shared/infiniteTable/InfiniteTable";
import { isStringEmptyOrSpaces, copyArrayWithObjects } from "core/helpers";
import { TableData } from "components/shared/infiniteTable/types";
import { mapTableSource } from "components/shared/infiniteTable/hooks";
import { OverallBulkReportStatus, OverallBulkReportResponse } from "api/rest/BulkReportStatus";
import { Snackbar } from "lib/snackbar";
import { Tab, Tabs } from "lib/tabs";
import { Box } from "lib/box";

const completedTableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.title,
            label: lang.title,
            minWidth: 400,
            maxWidth: 500,
            width: "100%",
            sortable: true,
            cellType: CellType.clickableText,
            isResizable: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: false
        },
        {
            dataKey: DataKey.organizationName,
            label: lang.organization,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: false
        },
        {
            dataKey: DataKey.createdDate,
            label: lang.requestedTime,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            order: TableSort.desc,
            cellType: CellType.time,
            isResizable: false
        },
        {
            dataKey: DataKey.lastModified,
            label: lang.completedTime,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        }
    ];
};

const erroredTableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.title,
            label: lang.title,
            minWidth: 400,
            maxWidth: 500,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: false
        },
        {
            dataKey: DataKey.organizationName,
            label: lang.organization,
            minWidth: 150,
            maxWidth: 200,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: false
        },
        {
            dataKey: DataKey.createdDate,
            label: lang.requestedTime,
            minWidth: 150,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            order: TableSort.desc,
            cellType: CellType.time,
            isResizable: false
        },
        {
            dataKey: DataKey.statusDetails,
            label: lang.errorInfo,
            minWidth: 500,
            maxWidth: 500,
            width: "100%",
            sortable: true,
            cellType: CellType.jsonInfo,
            isResizable: true
        }
    ];
};

const queuedTableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.title,
            label: lang.title,
            minWidth: 400,
            maxWidth: 500,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: false
        },
        {
            dataKey: DataKey.organizationName,
            label: lang.organization,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.plainText,
            isResizable: false
        },
        {
            dataKey: DataKey.queuePosition,
            secondaryDataKey: DataKey.progress,
            label: lang.status,
            minWidth: 300,
            maxWidth: 350,
            width: "100%",
            sortable: true,
            order: TableSort.desc,
            cellType: CellType.status,
            isResizable: false
        },
        {
            dataKey: DataKey.createdDate,
            label: lang.requestedTime,
            minWidth: 200,
            maxWidth: 300,
            width: "100%",
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        }
    ];
};

const BulkOverallStatus = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const { setLoading } = useLoading();
    const orgChartService = useOrgChartService();
    const [tabSelected, setTabNumber] = useState(0);
    const [searchStr, setSearchStr] = useState("");
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [queuedBulkExportList, setQueuedBulkExportList] = useState<OverallBulkReportStatus[]>([]);
    const [erroredBulkExportList, setErroredBulkExportList] = useState<OverallBulkReportStatus[]>([]);
    const [completedBulkExportList, setCompletedBulkExportList] = useState<OverallBulkReportStatus[]>([]);
    const completedTableColumns = completedTableColumnsList(lang);
    const erroredTableColumns = erroredTableColumnsList(lang);
    const queuedTableColumns = queuedTableColumnsList(lang);

    const handleSearchChange = (searchString: string): void => {
        setSearchStr(searchString);
    };

    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
        setTabNumber(newValue);
    };

    const getBulkExportList = (): void => {
        setLoading(true);
        orgChartService
            .getOverallBulkExportStatus(languageCode)
            .then((bulks: OverallBulkReportResponse): void => {
                setQueuedBulkExportList(bulks.queued);
                setCompletedBulkExportList(bulks.completed);
                setErroredBulkExportList(bulks.failed);
            })
            .finally(() => setLoading(false));
    };

    const filterBySearchString = (bulkExportStautsList: OverallBulkReportStatus[]): OverallBulkReportStatus[] => {
        let filtered = bulkExportStautsList;
        if (!isStringEmptyOrSpaces(searchStr)) {
            filtered = filtered.filter(
                (s: OverallBulkReportStatus) =>
                    s.title.toLowerCase().includes(searchStr.toLowerCase()) ||
                    s.email.toLowerCase().includes(searchStr.toLowerCase()) ||
                    s.organizationName.toLowerCase().includes(searchStr.toLowerCase()) ||
                    s.createdDate.toLowerCase().includes(searchStr.toLowerCase()) ||
                    (tabSelected === 1 && s.statusDetails.toLowerCase().includes(searchStr.toLowerCase())) ||
                    (tabSelected === 2 && s.lastModified.toLowerCase().includes(searchStr.toLowerCase()))
            );
        }

        return filtered;
    };

    const getQueuedTableData = (bulkExportStautsList: OverallBulkReportStatus[]): TableData[] => {
        const filteredData = filterBySearchString(bulkExportStautsList);
        return mapTableSource(filteredData, queuedTableColumns);
    };
    const getErroredTableData = (bulkExportStautsList: OverallBulkReportStatus[]): TableData[] => {
        const filteredData = filterBySearchString(bulkExportStautsList);
        return mapTableSource(filteredData, erroredTableColumns);
    };
    const getCompletedTableData = (bulkExportStautsList: OverallBulkReportStatus[]): TableData[] => {
        const filteredData = filterBySearchString(bulkExportStautsList);
        return mapTableSource(filteredData, completedTableColumns);
    };

    const handleDownloadBulkExport = (id: number): void => {
        orgChartService
            .downloadBulkReport(id)
            .then((blob: unknown) => {
                setSnackbar({ isOpen: true, message: lang.startDownloading });
                saveAs(blob as Blob, "export.zip");
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
            });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    useEffect(() => {
        getBulkExportList();
    }, [searchStr]);

    return (
        <Box
            height={"100%"}
            display={"grid"}
            gridTemplateRows={"153px calc(100% - 153px)"}
            gridTemplateAreas={`
            "appHeader"
            "appContent"
        `}
        >
            <Box gridArea={"appHeader"} alignSelf={"end"} borderBottom={theme.border.main}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={3} ml={3} mb={3}>
                    <Search onSearchChange={handleSearchChange} searchTerm={searchStr} width={280} />
                </Box>
                <Tabs
                    value={tabSelected}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleTabChange}
                    aria-label="tabs"
                >
                    <Tab label={`${lang.queued} (${queuedBulkExportList.length})`} data-testid="tab-bulk-queued-list" />
                    <Tab
                        label={`${lang.errored} (${erroredBulkExportList.length})`}
                        data-testid="tab-bulk-errored-list"
                    />
                    <Tab
                        label={`${lang.completed} (${completedBulkExportList.length})`}
                        data-testid="tab-bulk-completed-list"
                    />
                </Tabs>
            </Box>
            <Box gridArea={"appContent"} data-testid="bulkOverallStatusContainer">
                {tabSelected === 0 &&
                    (queuedBulkExportList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(queuedTableColumns)}
                            dataSource={getQueuedTableData(queuedBulkExportList)}
                            rowPadding={2}
                            defaultOrderByColumn={DataKey.createdDate}
                        />
                    ) : (
                        <EmptyPage image={EmptyResults} message={lang.noQueuedBulkExports} />
                    ))}
                {tabSelected === 1 &&
                    (erroredBulkExportList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(erroredTableColumns)}
                            dataSource={getErroredTableData(erroredBulkExportList)}
                            rowPadding={2}
                            defaultOrderByColumn={DataKey.createdDate}
                        />
                    ) : (
                        <EmptyPage image={EmptyResults} message={lang.noErroredBulkExports} />
                    ))}
                {tabSelected === 2 &&
                    (completedBulkExportList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(completedTableColumns)}
                            dataSource={getCompletedTableData(completedBulkExportList)}
                            onClickableTextClick={handleDownloadBulkExport}
                            rowPadding={2}
                            isDownloadLink={true}
                            defaultOrderByColumn={DataKey.createdDate}
                        />
                    ) : (
                        <EmptyPage image={EmptyResults} message={lang.noCompletedBulkExports} />
                    ))}
            </Box>
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
        </Box>
    );
};

export default BulkOverallStatus;
