import React, { ReactElement, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { useLang, useCommentsService } from "core/hooks";
import { Button } from "lib/button";
import { SurveyInfo } from "components/admin/results/interfaces";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { emptySurveyInfo } from "components/admin/results/init";
import { CommentSummaryPromptPayload } from "api/rest/interfaces/comments-service";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Popover } from "lib/popover";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";
const useStyles = makeStyles(() => ({
    paper: {
        marginTop: 174,
        marginLeft: 24
    },
    popoverContainer: {
        width: 1340,
        height: 640,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxSizing: "border-box"
    },
    popoverRowHeader: {
        display: "flex",
        height: 60,
        minHeight: 60,
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        borderBottom: theme.border.main,
        paddingLeft: 24,
        boxSizing: "border-box"
    },
    inputRoot: {
        paddingTop: "12px !important"
    },
    inputSize: {
        fontSize: "14px !important"
    }
}));

export enum CommentSummarySettingDataKey {
    opportunity = "opportunity",
    strength = "strength",
    suggestion = "suggestion",
    previous = "previous"
}

type Props = {
    summarySettingAnchorEl: Element | null;
    handleClosePopover: () => void;
    regenerateSummary: () => void;
    surveyId: SurveyInfo["id"];
};

export const CommentSummarySettingPopup = (props: Props): ReactElement => {
    const { lang } = useLang();
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [commentOpportunityPrompt, setCommentOpportunityPrompt] = useState("");
    const [commentStrengthPrompt, setCommentStrengthPrompt] = useState("");
    const [commentSuggestionPrompt, setCommentSuggestionPrompt] = useState("");
    const [commentPreiousPrompt, setCommentPreiousPrompt] = useState("");
    const commentsService = useCommentsService();

    const classes = useStyles();

    const handleCancelOnClick = (): void => {
        props.handleClosePopover();
    };

    const handleConfirmationClicked = (): void => {
        const commentPromptSettingsPayload: CommentSummaryPromptPayload = {
            surveyId: props.surveyId,
            opportunityPrompt: commentOpportunityPrompt,
            suggestionPrompt: commentSuggestionPrompt,
            strengthPrompt: commentStrengthPrompt,
            yearOverYearPrompt: commentPreiousPrompt
        };
        commentsService.updateCommentPromptSettings(commentPromptSettingsPayload).then((): void => {
            setConfirmDeleteDialogOpen(false);
            props.handleClosePopover();
            props.regenerateSummary();
        });
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === CommentSummarySettingDataKey.opportunity) {
            setCommentOpportunityPrompt(event.target.value);
        }
        if (event.target.name === CommentSummarySettingDataKey.suggestion) {
            setCommentSuggestionPrompt(event.target.value);
        }
        if (event.target.name === CommentSummarySettingDataKey.strength) {
            setCommentStrengthPrompt(event.target.value);
        }
        if (event.target.name === CommentSummarySettingDataKey.previous) {
            setCommentPreiousPrompt(event.target.value);
        }
    };

    useEffect(() => {
        if (props.surveyId && props.surveyId !== emptySurveyInfo.id) {
            commentsService.getCommentPromptSettings(props.surveyId).then(data => {
                setCommentOpportunityPrompt(data.opportunityPrompt);
                setCommentSuggestionPrompt(data.suggestionPrompt);
                setCommentStrengthPrompt(data.strengthPrompt);
                setCommentPreiousPrompt(data.yearOverYearPrompt);
            });
        }
    }, []);

    return (
        <>
            <Popover
                classes={{ paper: classes.paper }}
                open={props.summarySettingAnchorEl !== null}
                onClose={props.handleClosePopover}
                anchorEl={props.summarySettingAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
            >
                <Box width="1340px" height="640px" overflow={"hidden"}>
                    <div className={classes.popoverRowHeader}>
                        <Typography variant="h6">{lang.summarySettings}</Typography>
                    </div>
                    <Box display={"flex"} height={"260px"} minHeight={"260px"}>
                        <Box pl={3} pr={3} pb={0} pt={0} width={"50%"}>
                            <Typography pl={1} mb={1} mt={3}>
                                {lang.strengthPrompt}
                            </Typography>
                            <TextField
                                InputProps={{
                                    classes: { root: classes.inputRoot, input: classes.inputSize }
                                }}
                                name={CommentSummarySettingDataKey.strength}
                                value={commentStrengthPrompt}
                                onChange={handleChangeField}
                                multiline
                                rows={9}
                            />
                        </Box>
                        <Box pl={3} pr={3} pb={0} pt={0} width={"50%"}>
                            <Typography pl={1} mb={1} mt={3}>
                                {lang.previousPeriodPrompt}
                            </Typography>
                            <TextField
                                InputProps={{ classes: { root: classes.inputRoot, input: classes.inputSize } }}
                                name={CommentSummarySettingDataKey.previous}
                                value={commentPreiousPrompt}
                                onChange={handleChangeField}
                                multiline
                                rows={9}
                            />
                        </Box>
                    </Box>
                    <Box display={"flex"} height={"260px"} minHeight={"260px"} borderBottom={theme.border.main}>
                        <Box pl={3} pr={3} pb={0} pt={0} width={"50%"}>
                            <Typography pl={1} mb={1} mt={3}>
                                {lang.summaryPrompt}
                            </Typography>
                            <TextField
                                InputProps={{ classes: { root: classes.inputRoot, input: classes.inputSize } }}
                                name={CommentSummarySettingDataKey.opportunity}
                                value={commentOpportunityPrompt}
                                onChange={handleChangeField}
                                multiline
                                rows={9}
                            />
                        </Box>
                        <Box pl={3} pr={3} pb={0} pt={0} width={"50%"}>
                            <Typography pl={1} mb={1} mt={3}>
                                {lang.suggestionPrompt}
                            </Typography>
                            <TextField
                                InputProps={{ classes: { root: classes.inputRoot, input: classes.inputSize } }}
                                name={CommentSummarySettingDataKey.suggestion}
                                value={commentSuggestionPrompt}
                                onChange={handleChangeField}
                                multiline
                                rows={9}
                            />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        height={60}
                        maxHeight={60}
                        alignItems="center"
                        justifyContent="flex-end"
                        gap={1}
                        width="100%"
                        pr={2}
                    >
                        <Button
                            variant="text"
                            onClick={(): void => {
                                setConfirmDeleteDialogOpen(true);
                            }}
                            disabled={
                                commentOpportunityPrompt.trim() === "" ||
                                commentSuggestionPrompt.trim() === "" ||
                                commentStrengthPrompt.trim() === ""
                            }
                            data-testid="btn-comment-summary-setting-apply"
                        >
                            {lang.ok}
                        </Button>
                        <Button
                            variant="text"
                            onClick={handleCancelOnClick}
                            data-testid="btn-comment-summary-setting-cancel"
                        >
                            {lang.cancel}
                        </Button>
                    </Box>
                </Box>
            </Popover>
            {confirmDeleteDialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => setConfirmDeleteDialogOpen(false)}
                    open={confirmDeleteDialogOpen}
                    onConfirmationClicked={handleConfirmationClicked}
                    title={lang.confirmEditPrompt}
                    message={lang.editPromptMessage}
                    confirmButtonText={lang.ok}
                    cancelButtonVariant="text"
                />
            )}
        </>
    );
};
