import React, { FunctionComponent, ChangeEvent, useState, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import HelpIcon from "@mui/icons-material/Help";
import { mdiPlus } from "@mdi/js";
import Dialog from "@mui/material/Dialog";
import { Tooltip, Zoom } from "@mui/material";

import { DemographicOptions } from "components/reports/heatmap/interface";
import { SurveySection } from "managerPortal/context/interfaces";
import { useLang } from "core/hooks";
import { isStringEmptyOrSpaces } from "core/helpers";
import LookdownList from "./LookdownList";
import HeatmapLookdownList from "./HeatmapLookdownList";
import { UserSettings } from "components/admin/users/interface";
import { userSettingMutation } from "api/mutations";
import { emptySurveyInfo } from "components/admin/results/init";
import { Filter } from "components/filters/interfaces";
import RRLookdownList from "./RRLookdownList";
import { HrisFieldInfo } from "managerPortal/interfaces";
import KeyDriversLookdownList from "./KeyDriversLookdownList";
import { Box } from "lib/box";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Checkbox } from "lib/checkbox";
import { Button } from "lib/button";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Divider } from "lib/divider";
import { TextField } from "lib/text-field";
import { IconButtonV4 } from "lib/icon-button";
import { useUser } from "core/context/user/useUser";
import { FilterType } from "components/filters/enums";

type Props = {
    currentFilter: Filter;
    fileTitle?: string;
    dialogTitle: string;
    isOpen: boolean;
    onClose: () => void;
    hasSubTitle?: boolean;
    onSubmitCallback: (
        mainTitle: string,
        secondaryTitle: string,
        lookdownItems: LookdownItemExport[],
        heatmapFieldIds: number[],
        rrFieldIds: number[],
        keyDrivers: keyDriversExport[],
        showOverallCommentsSlide: boolean,
        heatmapCompareToOptions: number[],
        includeLookupOrgOverall: boolean
    ) => void;
    compareOptions: DemographicOptions[];
    surveySections: SurveySection[];
    contactFields: HrisFieldInfo[];
    responseCount: number;
    isBulkExport?: boolean;
    bulkExportDemographic: BulkExportDemographic;
    updateBulkExportDemographic: (bulkExportDemographic: BulkExportDemographic) => void;
    showPreviousPeriodOptionInHeatmap: boolean;
    disablePreviousPeriodOptionInHeatmap: boolean;
};

export interface LookdownItem {
    type: number;
    demographicFieldId: number;
    measureId: number;
    demographicField: string;
    measureField: string;
    lookupIds: number[];
}

export interface heatmapLookdownItem {
    demographicFieldId: number;
    demographicField: string;
    compareOption: HeatmapCompareOption;
}

export interface rrLookdownItem {
    demographicFieldId: number;
    demographicField: string;
}

export interface LookdownItemExport {
    type: number;
    demographicFieldId: number;
    measureId: number;
    lookupIds: number[];
}

export interface KeyDriversLookdownItem {
    isSection: boolean;
    measureId: number;
    measureField: string;
    columnLabel: string;
}

export interface keyDriversExport {
    isSection: boolean;
    id: number;
    columnLabel: string;
}

export interface BulkExportDemographic {
    demographicFieldId: number;
    demographicField: string;
}

export enum HeatmapCompareOption {
    overall,
    filtered,
    previousPeriod
}

export const DialogExportSlideWithLookdown: FunctionComponent<Props> = props => {
    const { user, setUser } = useUser();
    const { lang } = useLang();
    const { dialogTitle, fileTitle, isOpen, onClose, onSubmitCallback, hasSubTitle, currentFilter } = props;
    const [mainTitle, setMainTitle] = useState(fileTitle ?? "");
    const [secondaryTitle, setSecondaryTitle] = useState("");
    const [error, setError] = useState<boolean>(isStringEmptyOrSpaces(mainTitle));
    const [mainTitleHelperText, setMainTitleHelperText] = useState<string>("");
    const defaulHeatmapCompareOption =
        currentFilter && currentFilter.items && currentFilter.items.length > 0
            ? HeatmapCompareOption.filtered
            : HeatmapCompareOption.overall;
    const [lookdownItems, setLookdownItems] = useState<LookdownItem[]>([
        {
            type: -1,
            demographicFieldId: -1,
            measureId: -1,
            demographicField: "",
            measureField: "",
            lookupIds: [-1, -1]
        }
    ]);
    const [canExport, setCanExport] = useState<boolean>(true);
    const [heatmapLookdownItems, setHeatmapLookdownItems] = useState<heatmapLookdownItem[]>([
        {
            demographicFieldId: -1,
            demographicField: "",
            compareOption: defaulHeatmapCompareOption
        }
    ]);
    const [rrLookdownItems, setRRLookdownItems] = useState<rrLookdownItem[]>([
        {
            demographicFieldId: -1,
            demographicField: ""
        }
    ]);
    const [keyDriversLookdownItems, setKeyDriversLookdownItems] = useState<KeyDriversLookdownItem[]>([
        {
            isSection: false,
            measureId: -1,
            measureField: "",
            columnLabel: ""
        }
    ]);
    const [addLookdownDisabled, setAddLookdownDisabled] = useState<boolean>(true);
    const [addHeatmapLookdownDisabled, setAddHeatmapLookdownDisabled] = useState<boolean>(true);
    const [addRRLookdownDisabled, setAddRRLookdownDisabled] = useState<boolean>(true);
    const [addKeyDriversLookdownDisabled, setAddKeyDriversLookdownDisabled] = useState<boolean>(true);
    const [removeOverallComment, setRemoveOverallComment] = useState<boolean>(false);
    const [includeLookupOrgOverall, setIncludeLookupOrgOverall] = useState<boolean>(false);

    const labelRefs = useRef<HTMLDivElement[]>([]);
    const keyDriverMinResponses = 20;
    const maxCharacterForLabel = 30;

    const [updateUserSetting] = useMutation(userSettingMutation);
    const updateUserSettings = (snapshotSetting: UserSettings["snapshot"]): void => {
        const settings = {
            ...user.settings,
            snapshot: snapshotSetting
        };

        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };

        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const checkAddLookdownButtonStatus = (lookdownItems: LookdownItem[]): void => {
        const disableAddLookdown = lookdownItems.some(
            item =>
                (item.demographicField === "" && item.lookupIds[0] == -1 && item.lookupIds[1] == -1) ||
                item.measureField === ""
        );
        setAddLookdownDisabled(disableAddLookdown);
    };
    const checkAddHeatmapLookdownButtonStatus = (heatmapLookdownItems: heatmapLookdownItem[]): void => {
        const disableHeatmapAddLookdown = heatmapLookdownItems.some(item => item.demographicField === "");
        setAddHeatmapLookdownDisabled(disableHeatmapAddLookdown);
    };
    const checkAddRRLookdownButtonStatus = (rrLookdownItems: rrLookdownItem[]): void => {
        const disableRRAddLookdown = rrLookdownItems.some(item => item.demographicField === "");
        setAddRRLookdownDisabled(disableRRAddLookdown);
    };
    const checkAddKeyDriversLookdownButtonStatus = (keyDriversItems: KeyDriversLookdownItem[]): void => {
        if (keyDriversItems.length === 3) {
            setAddKeyDriversLookdownDisabled(true);
        } else {
            const disableKeyDriversAddLookdown = keyDriversItems.some(item => item.columnLabel === "");
            setAddKeyDriversLookdownDisabled(disableKeyDriversAddLookdown);
        }
    };
    const validation = (): boolean => {
        if (isStringEmptyOrSpaces(mainTitle)) {
            setError(true);
            setMainTitleHelperText(lang.dialogExportSlide.controls.errorMainTitle);
            return false;
        }

        return true;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "mainTitle") {
            const helperText = !value ? lang.dialogExportSlide.controls.errorMainTitle : "";

            setMainTitleHelperText(helperText);
            setError(!value);
            setMainTitle(value);
            return;
        }

        if (name === "subtitle") {
            setSecondaryTitle(value);
            return;
        }
    };

    const onExportClick = (): void => {
        const lookdownItemsExport = lookdownItems
            .filter(item => item.measureField !== "")
            .map(item => ({
                type: item.type,
                demographicFieldId: item.demographicFieldId,
                measureId: item.measureId,
                lookupIds: item.lookupIds.filter(id => id !== -1)
            }));
        const heatmapFieldIds = heatmapLookdownItems
            .filter(item => item.demographicFieldId !== -1)
            .map(item => item.demographicFieldId);
        const rrFieldIds = rrLookdownItems
            .filter(item => item.demographicFieldId !== -1)
            .map(item => item.demographicFieldId);
        let keyDriversExport = keyDriversLookdownItems
            .filter(item => item.measureId !== -1)
            .map(item => ({
                isSection: item.isSection,
                id: item.measureId,
                columnLabel: item.columnLabel
            }));
        if (props.responseCount < keyDriverMinResponses) {
            keyDriversExport = [];
        }
        const heatmapCompareToOptions = heatmapLookdownItems
            .filter(item => item.demographicFieldId !== -1)
            .map(item => item.compareOption);
        validation() &&
            onSubmitCallback(
                mainTitle,
                secondaryTitle,
                lookdownItemsExport,
                heatmapFieldIds,
                rrFieldIds,
                keyDriversExport,
                !removeOverallComment,
                heatmapCompareToOptions,
                includeLookupOrgOverall
            );
    };

    const saveUserLookdownItemSettings = (items: LookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.snapshot,
            lookdownItems: items.filter(item => item.measureId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const saveUserHeatmapLookdownSettings = (items: heatmapLookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.snapshot,
            heatmapLookdownItems: items.filter(item => item.demographicFieldId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const saveUserKeyDriversLookdownSettings = (items: KeyDriversLookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.snapshot,
            keyDriversLookdownItems: items.filter(item => item.measureId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const saveUserRRLookdownSettings = (items: rrLookdownItem[]): void => {
        const updatedSettings = {
            ...user.settings.snapshot,
            rrLookdownItems: items.filter(item => item.demographicFieldId !== -1)
        };
        updateUserSettings(updatedSettings);
    };

    const clearUserLookdownItemSettings = (): void => {
        const updatedSettings = {
            ...user.settings.snapshot,
            lookdownItems: [],
            heatmapLookdownItems: [],
            rrLookdownItems: [],
            keyDriversLookdownItems: []
        };
        updateUserSettings(updatedSettings);
    };

    const onClearAll = (): void => {
        setMainTitle("");
        setMainTitleHelperText(lang.dialogExportSlide.controls.errorMainTitle);
        setSecondaryTitle("");
        setLookdownItems([
            {
                type: -1,
                demographicFieldId: -1,
                measureId: -1,
                demographicField: "",
                measureField: "",
                lookupIds: [-1, -1]
            }
        ]);
        setHeatmapLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: "",
                compareOption: defaulHeatmapCompareOption
            }
        ]);
        setRRLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: ""
            }
        ]);
        setKeyDriversLookdownItems([
            {
                isSection: false,
                measureId: -1,
                measureField: "",
                columnLabel: ""
            }
        ]);
        setError(true);
        clearUserLookdownItemSettings();
        setAddLookdownDisabled(true);
        setAddHeatmapLookdownDisabled(true);
        setAddRRLookdownDisabled(true);
        setAddKeyDriversLookdownDisabled(true);
    };
    const handleAddLookdownItem = (): void => {
        setAddLookdownDisabled(true);
        const newLookdownItem = {
            type: -1,
            demographicFieldId: -1,
            measureId: -1,
            demographicField: "",
            measureField: "",
            lookupIds: [-1, -1]
        };
        setLookdownItems([...lookdownItems, newLookdownItem]);
        saveUserLookdownItemSettings([...lookdownItems, newLookdownItem]);
    };

    const handleAddHeatmapLookdownItem = (): void => {
        setAddHeatmapLookdownDisabled(true);
        const newHeatmapLookdownItem = {
            demographicFieldId: -1,
            demographicField: "",
            compareOption: defaulHeatmapCompareOption
        };
        setHeatmapLookdownItems([...heatmapLookdownItems, newHeatmapLookdownItem]);
        saveUserHeatmapLookdownSettings([...heatmapLookdownItems, newHeatmapLookdownItem]);
    };

    const handleAddRRLookdownItem = (): void => {
        setAddRRLookdownDisabled(true);
        const newRRLookdownItem = {
            demographicFieldId: -1,
            demographicField: ""
        };
        setRRLookdownItems([...rrLookdownItems, newRRLookdownItem]);
        saveUserRRLookdownSettings([...rrLookdownItems, newRRLookdownItem]);
    };

    const handleAddKeyDriversLookdownItem = (): void => {
        setAddKeyDriversLookdownDisabled(true);
        const newKeyDriversLookdownItem = {
            isSection: false,
            measureId: -1,
            measureField: "",
            columnLabel: ""
        };
        setKeyDriversLookdownItems([...keyDriversLookdownItems, newKeyDriversLookdownItem]);
        saveUserKeyDriversLookdownSettings([...keyDriversLookdownItems, newKeyDriversLookdownItem]);
    };
    const handleUpdateLookdownItem = (item: LookdownItem, index: number): void => {
        const items = [...lookdownItems];
        items[index] = item;
        setLookdownItems([...items]);
        saveUserLookdownItemSettings([...items]);
        checkAddLookdownButtonStatus([...items]);
    };

    const handleUpdateheatMapLookdownItem = (item: heatmapLookdownItem, index: number): void => {
        const items = [...heatmapLookdownItems];
        items[index] = item;
        setHeatmapLookdownItems([...items]);
        saveUserHeatmapLookdownSettings([...items]);
        checkAddHeatmapLookdownButtonStatus([...items]);
    };
    const handleUpdateRRLookdownItem = (item: rrLookdownItem, index: number): void => {
        const items = [...rrLookdownItems];
        items[index] = item;
        setRRLookdownItems([...items]);
        saveUserRRLookdownSettings([...items]);
        checkAddRRLookdownButtonStatus([...items]);
    };

    const handleUpdateKeyDriversLookdownItem = (item: KeyDriversLookdownItem, index: number): void => {
        const items = [...keyDriversLookdownItems];
        items[index] = item;
        setKeyDriversLookdownItems([...items]);
        saveUserKeyDriversLookdownSettings([...items]);
        checkAddKeyDriversLookdownButtonStatus([...items]);
    };
    const handleUpdateColumnLabelValue = (label: string, index: number): void => {
        const items = [...keyDriversLookdownItems];
        items[index].columnLabel = label;
        setKeyDriversLookdownItems([...items]);
        checkAddKeyDriversLookdownButtonStatus([...items]);
    };
    const handleColumnLabelOnBlur = (): void => {
        saveUserKeyDriversLookdownSettings([...keyDriversLookdownItems]);
    };

    const handleDeleteLookdownItem = (index: number): void => {
        if (lookdownItems && lookdownItems.length === 1) {
            resetLookdownItem();
        } else {
            setLookdownItems(lookdownItems.filter((_, i) => i !== index));
            saveUserLookdownItemSettings([...lookdownItems.filter((_, i) => i !== index)]);
            checkAddLookdownButtonStatus(lookdownItems.filter((_, i) => i !== index));
        }
    };

    const handleDeleteheatMapLookdownItem = (index: number): void => {
        if (heatmapLookdownItems && heatmapLookdownItems.length === 1) {
            resetHeatmapLookdownItem();
        } else {
            setHeatmapLookdownItems(heatmapLookdownItems.filter((_, i) => i !== index));
            saveUserHeatmapLookdownSettings([...heatmapLookdownItems.filter((_, i) => i !== index)]);
            checkAddHeatmapLookdownButtonStatus(heatmapLookdownItems.filter((_, i) => i !== index));
        }
    };

    const handleDeleteRRLookdownItem = (index: number): void => {
        if (rrLookdownItems && rrLookdownItems.length === 1) {
            resetRRLookdownItem();
        } else {
            setRRLookdownItems(rrLookdownItems.filter((_, i) => i !== index));
            saveUserRRLookdownSettings([...rrLookdownItems.filter((_, i) => i !== index)]);
            checkAddRRLookdownButtonStatus(rrLookdownItems.filter((_, i) => i !== index));
        }
    };
    const handleDeleteKeyDriversLookdownItem = (index: number): void => {
        if (keyDriversLookdownItems && keyDriversLookdownItems.length === 1) {
            resetKeyDriversLookdownItem();
        } else {
            setKeyDriversLookdownItems(keyDriversLookdownItems.filter((_, i) => i !== index));
            saveUserKeyDriversLookdownSettings([...keyDriversLookdownItems.filter((_, i) => i !== index)]);
            checkAddKeyDriversLookdownButtonStatus(keyDriversLookdownItems.filter((_, i) => i !== index));
        }
    };

    const resetLookdownItem = (): void => {
        setAddLookdownDisabled(true);
        setLookdownItems([
            {
                type: -1,
                demographicFieldId: -1,
                measureId: -1,
                demographicField: "",
                measureField: "",
                lookupIds: [-1, -1]
            }
        ]);
        saveUserLookdownItemSettings([
            {
                type: -1,
                demographicFieldId: -1,
                measureId: -1,
                demographicField: "",
                measureField: "",
                lookupIds: [-1, -1]
            }
        ]);
    };
    const resetHeatmapLookdownItem = (): void => {
        setAddHeatmapLookdownDisabled(true);
        setHeatmapLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: "",
                compareOption: defaulHeatmapCompareOption
            }
        ]);
        saveUserHeatmapLookdownSettings([
            {
                demographicFieldId: -1,
                demographicField: "",
                compareOption: defaulHeatmapCompareOption
            }
        ]);
    };
    const resetRRLookdownItem = (): void => {
        setAddRRLookdownDisabled(true);
        setRRLookdownItems([
            {
                demographicFieldId: -1,
                demographicField: ""
            }
        ]);
        saveUserRRLookdownSettings([
            {
                demographicFieldId: -1,
                demographicField: ""
            }
        ]);
    };
    const resetKeyDriversLookdownItem = (): void => {
        setAddKeyDriversLookdownDisabled(true);
        setKeyDriversLookdownItems([
            {
                isSection: false,
                measureId: -1,
                measureField: "",
                columnLabel: ""
            }
        ]);
        saveUserKeyDriversLookdownSettings([
            {
                isSection: false,
                measureId: -1,
                measureField: "",
                columnLabel: ""
            }
        ]);
    };

    const restoreUserSettings = async (snapshotSetting: UserSettings["snapshot"]): Promise<void> => {
        if (snapshotSetting.id && snapshotSetting.id !== emptySurveyInfo.id) {
            const DemographicFieldIds = props.contactFields.map(list => list.fieldId);
            if (snapshotSetting.lookdownItems && snapshotSetting.lookdownItems.length > 0) {
                if (
                    currentFilter &&
                    currentFilter.items &&
                    currentFilter.items.length == 0 &&
                    !props.isBulkExport &&
                    snapshotSetting.lookdownItems.some(item => item.lookupIds[0] !== -1 || item.lookupIds[1] !== -1)
                ) {
                    resetLookdownItem();
                    return;
                }
                setLookdownItems([...snapshotSetting.lookdownItems]);
                checkAddLookdownButtonStatus([...snapshotSetting.lookdownItems]);
                //check self selected demographic fields
                const lookdownItemsLookdownFieldIds = snapshotSetting.lookdownItems.map(
                    item => item.demographicFieldId
                );
                const lookdownItemsLookUpFieldIds = snapshotSetting.lookdownItems
                    .map(item => item.lookupIds)
                    .reduce((a, b) => a.concat(b), []);
                const lookdownItemsFieldIds = lookdownItemsLookdownFieldIds
                    .concat(lookdownItemsLookUpFieldIds)
                    .filter(id => id !== -1);
                lookdownItemsFieldIds.forEach(id => {
                    if (!DemographicFieldIds.includes(id)) {
                        resetLookdownItem();
                    }
                });
            }
            if (snapshotSetting.heatmapLookdownItems && snapshotSetting.heatmapLookdownItems.length > 0) {
                let updatedHeatmapLookdownItems = [...snapshotSetting.heatmapLookdownItems];
                if (
                    currentFilter &&
                    currentFilter.items &&
                    currentFilter.items.length === 0 &&
                    !user.isRestricted &&
                    !props.isBulkExport &&
                    updatedHeatmapLookdownItems.filter(item => item.compareOption === HeatmapCompareOption.filtered)
                        .length > 0
                ) {
                    updatedHeatmapLookdownItems = updatedHeatmapLookdownItems.filter(
                        item => item.compareOption !== HeatmapCompareOption.filtered
                    );
                }
                if (
                    (props.disablePreviousPeriodOptionInHeatmap || !props.showPreviousPeriodOptionInHeatmap) &&
                    updatedHeatmapLookdownItems.filter(
                        item => item.compareOption === HeatmapCompareOption.previousPeriod
                    ).length > 0
                ) {
                    updatedHeatmapLookdownItems = updatedHeatmapLookdownItems.filter(
                        item => item.compareOption !== HeatmapCompareOption.previousPeriod
                    );
                }
                if (updatedHeatmapLookdownItems.length === 0) {
                    updatedHeatmapLookdownItems = [
                        {
                            demographicFieldId: -1,
                            demographicField: "",
                            compareOption: defaulHeatmapCompareOption
                        }
                    ];
                }
                saveUserHeatmapLookdownSettings(updatedHeatmapLookdownItems);
                setHeatmapLookdownItems(updatedHeatmapLookdownItems);
                checkAddHeatmapLookdownButtonStatus(updatedHeatmapLookdownItems);
                //check self selected demographic fields
                const heatmapLookdownItemsFieldIds = snapshotSetting.heatmapLookdownItems.map(
                    item => item.demographicFieldId
                );
                heatmapLookdownItemsFieldIds.forEach(id => {
                    if (!DemographicFieldIds.includes(id)) {
                        resetHeatmapLookdownItem();
                    }
                });
            }
            if (snapshotSetting.rrLookdownItems && snapshotSetting.rrLookdownItems.length > 0) {
                setRRLookdownItems([...snapshotSetting.rrLookdownItems]);
                checkAddRRLookdownButtonStatus([...snapshotSetting.rrLookdownItems]);
                //check self selected demographic fields
                const rrLookdownItemsFieldIds = snapshotSetting.rrLookdownItems.map(item => item.demographicFieldId);
                rrLookdownItemsFieldIds.forEach(id => {
                    if (!DemographicFieldIds.includes(id)) {
                        resetRRLookdownItem();
                    }
                });
            }
            if (snapshotSetting.keyDriversLookdownItems && snapshotSetting.keyDriversLookdownItems.length > 0) {
                setKeyDriversLookdownItems([...snapshotSetting.keyDriversLookdownItems]);
                checkAddKeyDriversLookdownButtonStatus([...snapshotSetting.keyDriversLookdownItems]);
            }
            if (
                snapshotSetting.showOverallCommentsSlide !== null &&
                snapshotSetting.showOverallCommentsSlide !== undefined
            ) {
                setRemoveOverallComment(!snapshotSetting.showOverallCommentsSlide);
            }
            if (
                snapshotSetting.includeLookupOrgOverall !== null &&
                snapshotSetting.includeLookupOrgOverall !== undefined
            ) {
                setIncludeLookupOrgOverall(snapshotSetting.includeLookupOrgOverall);
            }
        }
    };

    const handleOnFocus = (e: React.FocusEvent, index: number): void => {
        if (labelRefs.current[index]) {
            const textInput = labelRefs.current[index] as HTMLInputElement;
            textInput.select();
        }
    };

    const handleFieldSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            clearBulkExportDemographicField();
            return;
        }
        const updatedBulkExport = { demographicFieldId: value.id, demographicField: value.label };
        props.updateBulkExportDemographic(updatedBulkExport);
    };

    const clearBulkExportDemographicField = (): void => {
        const updatedBulkExport = { demographicFieldId: -1, demographicField: "" };
        props.updateBulkExportDemographic(updatedBulkExport);
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked = event.target.checked;
        const checkboxName = event.target.name;
        if (checkboxName == "removeOverallComment") {
            setRemoveOverallComment(checked);
            const updatedSettings = {
                ...user.settings.snapshot,
                showOverallCommentsSlide: !checked
            };
            updateUserSettings(updatedSettings);
            return;
        }
        if (checkboxName == "includeLookupOrgOverall") {
            setIncludeLookupOrgOverall(checked);
            const updatedSettings = {
                ...user.settings.snapshot,
                includeLookupOrgOverall: checked
            };
            updateUserSettings(updatedSettings);
            return;
        }
    };

    useEffect(() => {
        let canExportLookdown = true;
        let canExportheatmap = true;
        let canExportRR = true;
        let canExportKeyDrivers = true;
        let canExportBulkExport = true;
        const checkCanExport = [];
        //check lookdown can export or not
        if (lookdownItems.length === 1) {
            if (
                lookdownItems[0].demographicField === "" &&
                lookdownItems[0].measureField === "" &&
                lookdownItems[0].lookupIds[0] === -1 &&
                lookdownItems[0].lookupIds[1] === -1
            ) {
                canExportLookdown = true;
            } else if (
                (lookdownItems[0].demographicField !== "" ||
                    lookdownItems[0].lookupIds[0] !== -1 ||
                    lookdownItems[0].lookupIds[1] !== -1) &&
                lookdownItems[0].measureField !== ""
            ) {
                canExportLookdown = true;
            } else {
                canExportLookdown = false;
            }
        } else if (lookdownItems.length > 1) {
            for (const item of lookdownItems) {
                if (
                    (item.demographicFieldId === -1 && item.lookupIds[0] === -1 && item.lookupIds[1] === -1) ||
                    item.measureId === -1
                ) {
                    canExportLookdown = false;
                    break;
                }
            }
        }
        //check heatmap can export or not
        if (heatmapLookdownItems.length > 1) {
            for (const item of heatmapLookdownItems) {
                if (item.demographicFieldId === -1) {
                    canExportheatmap = false;
                    break;
                }
            }
        }
        //check response rate can export or not
        if (rrLookdownItems.length > 1) {
            for (const item of rrLookdownItems) {
                if (item.demographicFieldId === -1) {
                    canExportRR = false;
                    break;
                }
            }
        }
        //check keyDrivers can export or not
        if (props.responseCount <= keyDriverMinResponses) {
            canExportKeyDrivers = true;
        } else if (keyDriversLookdownItems.length === 1) {
            if (keyDriversLookdownItems[0].columnLabel === "" && keyDriversLookdownItems[0].measureId === -1) {
                canExportKeyDrivers = true;
            } else if (
                keyDriversLookdownItems[0].columnLabel !== "" &&
                keyDriversLookdownItems[0].measureId !== -1 &&
                keyDriversLookdownItems[0].columnLabel.length <= maxCharacterForLabel
            ) {
                canExportKeyDrivers = true;
            } else {
                canExportKeyDrivers = false;
            }
        } else if (keyDriversLookdownItems.length > 1) {
            for (const item of keyDriversLookdownItems) {
                if (item.columnLabel === "" || item.measureId === -1) {
                    canExportKeyDrivers = false;
                    break;
                }
                if (item.columnLabel.length > maxCharacterForLabel) {
                    canExportKeyDrivers = false;
                    break;
                }
            }
        }
        //check bulk export demographic
        if (props.isBulkExport) {
            canExportBulkExport =
                props.bulkExportDemographic.demographicFieldId !== -1 &&
                !lookdownItems
                    .map(item => item.demographicFieldId)
                    .includes(props.bulkExportDemographic.demographicFieldId);
        }

        checkCanExport.push(canExportLookdown);
        checkCanExport.push(canExportheatmap);
        checkCanExport.push(canExportRR);
        checkCanExport.push(canExportKeyDrivers);
        checkCanExport.push(canExportBulkExport);

        const canExport = checkCanExport.every(check => check === true);
        setCanExport(canExport);
    }, [
        lookdownItems,
        heatmapLookdownItems,
        rrLookdownItems,
        keyDriversLookdownItems,
        currentFilter,
        props.bulkExportDemographic
    ]);

    //restore user settings
    useEffect(() => {
        if (user.settings.snapshot) {
            restoreUserSettings(user.settings.snapshot);
        }
    }, []);

    const selectedDemographic = (id: number) => {
        const found = props.compareOptions.find(s => s.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.name
            };
        }
        return {
            id: -1,
            label: ""
        };
    };
    const dialogStyle = {
        height: 800,
        minWidth: 1000,
        maxWidth: 1000
    };
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{ sx: dialogStyle }}
            onKeyUp={e => {
                if (e.key === "Enter" && !error && canExport) {
                    onExportClick();
                }
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between" height={60} p={3}>
                <Typography variant="h4">{dialogTitle}</Typography>
                <Button data-testid="btn-export-clearAll" onClick={onClearAll} variant="text">
                    {lang.clearAll}
                </Button>
            </Box>
            <Divider />
            <Box height="calc(100% - 120px)" overflow="auto">
                <Box
                    display="flex"
                    gap={2}
                    p={2}
                    pb={
                        lookdownItems
                            .map(item => item.demographicFieldId)
                            .includes(props.bulkExportDemographic.demographicFieldId)
                            ? 2
                            : 3
                    }
                    pl={4}
                >
                    <TextField
                        autoFocus
                        data-testid="text-field-export-title"
                        name="mainTitle"
                        error={error}
                        label={lang.dialogExportSlide.controls.labelMainTitle}
                        onChange={handleChange}
                        value={mainTitle}
                        helperText={mainTitleHelperText}
                        style={{ width: 384 }}
                        FormHelperTextProps={{
                            style: {
                                marginBottom:
                                    props.bulkExportDemographic.demographicFieldId !== -1 &&
                                    lookdownItems
                                        .map(item => item.demographicFieldId)
                                        .includes(props.bulkExportDemographic.demographicFieldId)
                                        ? "18px"
                                        : "0px"
                            }
                        }}
                    />
                    {hasSubTitle && !props.isBulkExport && (
                        <TextField
                            data-testid="text-field-export-subtitle"
                            name="subtitle"
                            label={lang.dialogExportSlide.controls.labelSubTitle}
                            onChange={handleChange}
                            value={secondaryTitle}
                            multiline
                            style={{ width: 384 }}
                        />
                    )}
                    {props.isBulkExport && (
                        <Box minWidth={250} display={"flex"} flexDirection={"column"}>
                            <Autocomplete
                                id={`bulk-export-demographic`}
                                options={props.compareOptions
                                    .filter(option => option.id !== -1)
                                    .map(s => {
                                        return {
                                            id: s.id,
                                            label: s.name
                                        };
                                    })}
                                noOptionsText={lang.noOptionsAvailable}
                                placeholder={lang.demographics}
                                value={selectedDemographic(props.bulkExportDemographic.demographicFieldId)}
                                getOptionDisabled={option => {
                                    return option.id === props.bulkExportDemographic.demographicFieldId;
                                }}
                                onChange={handleFieldSelected}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                            {props.bulkExportDemographic.demographicFieldId !== -1 &&
                                lookdownItems
                                    .map(item => item.demographicFieldId)
                                    .includes(props.bulkExportDemographic.demographicFieldId) && (
                                    <Typography fontSize={12} fontWeight={400} color={"red"}>
                                        {lang.bulkDemographicSame}
                                    </Typography>
                                )}
                        </Box>
                    )}
                </Box>
                <Divider />
                <Box p={2} pl={3}>
                    <Typography>{lang.addResponseRateLookdown}</Typography>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={1.5}>
                    {rrLookdownItems &&
                        rrLookdownItems.length > 0 &&
                        rrLookdownItems.map((item: rrLookdownItem, index) => {
                            return (
                                <RRLookdownList
                                    key={index}
                                    compareOptions={props.compareOptions}
                                    rrLookdownItem={item}
                                    rrLookdownItems={rrLookdownItems}
                                    index={index}
                                    updateLookdownItem={handleUpdateRRLookdownItem}
                                    deleteLookdownItem={handleDeleteRRLookdownItem}
                                    currentFilter={currentFilter}
                                />
                            );
                        })}
                    <Box style={{ paddingLeft: "6px", paddingTop: "6px" }}>
                        <IconButtonV4
                            path={mdiPlus}
                            onClick={handleAddRRLookdownItem}
                            tooltip={lang.clickThePlusButtonToAddANewRRLookdown}
                            dataTestid="add-new-rrLookdown-button"
                            disabled={addRRLookdownDisabled}
                            padding="12px 224px 12px 24px"
                        />
                    </Box>
                </Box>
                <Divider />
                {(!user.isRestricted && currentFilter && currentFilter.items && currentFilter.items.length > 0) ||
                props.isBulkExport ? (
                    <Box p={1} pl={3} gap={3} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Typography>{lang.addLookupLookdown}</Typography>
                            <Tooltip TransitionComponent={Zoom} title={lang.lookupLookdownTooltip} placement="right">
                                <a
                                    href={`https://docs.talentmap.com/knowledge-base/step-5-analyze-interpret-and-report/compass-reports/snapshot/export-to-powerpoint/export-lookdown`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IconButton size={"small"}>
                                        <HelpIcon />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>

                        <Checkbox
                            checked={includeLookupOrgOverall}
                            name={"includeLookupOrgOverall"}
                            onChange={handleChangeField}
                            label={lang.includeLookupOrgOverall}
                        />
                    </Box>
                ) : (
                    <Box p={1} pl={3} gap={3} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Typography>{lang.addLookdown}</Typography>
                            <Tooltip TransitionComponent={Zoom} title={lang.lookdownTooltip} placement="right">
                                <IconButton size={"small"}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Checkbox
                            checked={includeLookupOrgOverall}
                            name={"includeLookupOrgOverall"}
                            onChange={handleChangeField}
                            label={lang.includeLookupOrgOverall}
                        />
                    </Box>
                )}
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={1.5}>
                    {lookdownItems &&
                        lookdownItems.length > 0 &&
                        lookdownItems.map((item: LookdownItem, index) => {
                            return (
                                <LookdownList
                                    key={index}
                                    compareOptions={props.compareOptions}
                                    surveySections={props.surveySections}
                                    lookdownItem={item}
                                    index={index}
                                    updateLookdownItem={handleUpdateLookdownItem}
                                    deleteLookdownItem={handleDeleteLookdownItem}
                                    showLookup={
                                        !user.isRestricted &&
                                        ((currentFilter && currentFilter.items && currentFilter.items.length > 0) ||
                                            (props.isBulkExport !== undefined && props.isBulkExport))
                                    }
                                    disabledDemographicIds={currentFilter.items
                                        .filter(
                                            item =>
                                                item.filterType === FilterType.contactField &&
                                                item.field.id !== undefined
                                        )
                                        .map(item => item.field.id as number)}
                                    bulkDemographicFieldId={props.bulkExportDemographic.demographicFieldId}
                                />
                            );
                        })}
                    <Box style={{ paddingLeft: "6px", paddingTop: "6px" }}>
                        <IconButtonV4
                            path={mdiPlus}
                            onClick={handleAddLookdownItem}
                            tooltip={lang.clickThePlusButtonToAddANewLookdown}
                            dataTestid="add-new-lookdown-button"
                            disabled={addLookdownDisabled}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box p={2} pl={3}>
                    <Typography>{lang.addHeatmapLookdown}</Typography>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={1.5}>
                    {heatmapLookdownItems &&
                        heatmapLookdownItems.length > 0 &&
                        heatmapLookdownItems.map((item: heatmapLookdownItem, index) => {
                            return (
                                <HeatmapLookdownList
                                    key={index}
                                    compareOptions={props.compareOptions}
                                    heatmapLookdownItem={item}
                                    heatmapLookdownItems={heatmapLookdownItems}
                                    index={index}
                                    updateLookdownItem={handleUpdateheatMapLookdownItem}
                                    deleteLookdownItem={handleDeleteheatMapLookdownItem}
                                    currentFilter={currentFilter}
                                    isBulkExport={props.isBulkExport || false}
                                    showPreviousPeriodOptionInHeatmap={props.showPreviousPeriodOptionInHeatmap}
                                    disablePreviousPeriodOptionInHeatmap={props.disablePreviousPeriodOptionInHeatmap}
                                    previewMode={false}
                                />
                            );
                        })}
                    <Box style={{ paddingLeft: "6px", paddingTop: "6px" }}>
                        <IconButtonV4
                            path={mdiPlus}
                            onClick={handleAddHeatmapLookdownItem}
                            tooltip={lang.clickThePlusButtonToAddANewHeatmapLookdown}
                            dataTestid="add-new-hetmapLookdown-button"
                            disabled={addHeatmapLookdownDisabled}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box p={1} pl={3} display="flex" alignItems="center">
                    <Typography>{lang.addKeyDriversLookdown}</Typography>
                    <Tooltip TransitionComponent={Zoom} title={lang.keyDriverslookdownTooltip} placement="right">
                        <IconButton size={"small"}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" p={2} pl={2} gap={2}>
                    {props.responseCount < keyDriverMinResponses && (
                        <Typography>{lang.responseCountWarning}</Typography>
                    )}
                    {keyDriversLookdownItems &&
                        keyDriversLookdownItems.length > 0 &&
                        keyDriversLookdownItems.map((item: KeyDriversLookdownItem, index) => {
                            return (
                                <KeyDriversLookdownList
                                    key={item.measureId}
                                    surveySections={props.surveySections}
                                    keyDriversLookdownItem={item}
                                    keyDriversLookdownItems={keyDriversLookdownItems}
                                    index={index}
                                    updateLookdownItem={handleUpdateKeyDriversLookdownItem}
                                    deleteLookdownItem={handleDeleteKeyDriversLookdownItem}
                                    responseCount={props.responseCount}
                                    updateColumnLabelValue={handleUpdateColumnLabelValue}
                                    columnLabelOnBlur={handleColumnLabelOnBlur}
                                    inputRef={(el: HTMLDivElement): HTMLDivElement => (labelRefs.current[index] = el)}
                                    handleOnFocus={handleOnFocus}
                                    previewMode={false}
                                />
                            );
                        })}
                    {props.responseCount >= keyDriverMinResponses && (
                        <Box style={{ paddingLeft: "6px", paddingTop: "6px" }}>
                            <IconButtonV4
                                path={mdiPlus}
                                onClick={handleAddKeyDriversLookdownItem}
                                tooltip={lang.clickThePlusButtonToAddANewKeyDriversLookdown}
                                dataTestid="add-new-keyDriversLookdown-button"
                                disabled={addKeyDriversLookdownDisabled}
                            />
                        </Box>
                    )}
                </Box>
                <Divider />
                <Box p={1} pl={3}>
                    <Checkbox
                        checked={removeOverallComment}
                        name={"removeOverallComment"}
                        onChange={handleChangeField}
                        label={lang.removeOverallComment}
                    />
                </Box>
            </Box>
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="flex-end" height={60} p={3} gap={2}>
                <Button
                    onClick={onExportClick}
                    disabled={error || !canExport}
                    variant="text"
                    data-testid={props.isBulkExport ? "btn-dialog-bulk-export-slide" : "btn-dialog-export-slide"}
                >
                    {lang.ok}
                </Button>
                <Button data-testid="btn-export-cancel" onClick={onClose} variant="text">
                    {lang.cancel}
                </Button>
            </Box>
        </Dialog>
    );
};
