import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { useLang } from "core/hooks";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { theme } from "lib/theme";

const useStyles = makeStyles(() => ({
    noBenchmark: {
        width: "100%",
        height: "100%",
        position: "relative"
    },
    withBenchmark: {
        width: "calc(100% - 50px)",
        height: "100%",
        position: "relative"
    },
    barScale: {
        display: "flex",
        width: "100%",
        height: "100%"
    },
    barSection: {
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    }
}));

const barPercentage = {
    position: "absolute",
    top: 0,
    right: 3,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontWeight: "medium"
};

const styleTypographNoOverall = (percentageFontSize: number | undefined) => {
    return {
        position: "absolute",
        top: 0,
        right: 3,
        left: 2,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: theme.palette.text.secondary,
        fontSize: `${percentageFontSize || 16} !important`
    };
};

interface Props {
    values: number[];
    colors: string[];
    displayPercentage?: boolean;
    withBenchmark?: boolean;
    displayAllPercentage?: boolean;
    sectionLevel?: boolean;
    percentageFontSize?: number;
    fromComment?: boolean;
}

const StackedBar = (props: Props): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles(props);
    const {
        values,
        colors,
        displayPercentage,
        withBenchmark,
        sectionLevel,
        fromComment,
        displayAllPercentage,
        percentageFontSize
    } = props;

    if (!Array.isArray(values)) return <></>;

    const total = values ? values.reduce((a: number, b: number): number => a + b, 0) : -1;
    let percentage = "";

    //adjustment to always have a bar with 100% width.
    const delta = 100 - total;
    const deltaFixIndex = 3 - values.filter((item: number) => item === 0).length;

    if (total >= 0) {
        percentage = values[values.length - 1] ? values[values.length - 1].toFixed(0) + "%" : "";
    }

    return (
        <div className={withBenchmark ? classes.withBenchmark : classes.noBenchmark}>
            {total <= 0 && fromComment ? (
                <></>
            ) : total <= 0 && sectionLevel ? (
                <Typography variant="body1" sx={styleTypographNoOverall(percentageFontSize)}>
                    {lang.noData}
                </Typography>
            ) : total <= 0 && !sectionLevel ? (
                <Typography variant="body1" sx={styleTypographNoOverall(percentageFontSize)}>
                    {lang.noItemAvailable}
                </Typography>
            ) : (
                <>
                    <div className={classes.barScale}>
                        {values.map((item: number, index: number): ReactElement => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        background: colors[index],

                                        width: item === 0 ? item + "%" : item + delta / deltaFixIndex + "%"
                                    }}
                                    data-testid={`bar-index-${index}`}
                                    className={classes.barSection}
                                >
                                    {displayAllPercentage && item !== 0 && (
                                        <Box mr={0.75} display="flex" alignItems="center">
                                            <Typography
                                                variant="body1"
                                                fontWeight={500}
                                                sx={{ fontSize: percentageFontSize || 16 }}
                                            >
                                                {item}
                                            </Typography>
                                        </Box>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {displayPercentage && (
                        <Typography variant="body1" sx={barPercentage}>
                            {percentage}
                        </Typography>
                    )}
                </>
            )}
        </div>
    );
};

export default StackedBar;
