import React, { ReactElement } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import { rrLookdownItem } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { DemographicOptions } from "components/reports/heatmap/interface";
import { useLang } from "core/hooks";
import { Filter } from "components/filters/interfaces";
import { Box } from "lib/box";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { IconButton } from "lib/icon-button";

type Props = {
    compareOptions: DemographicOptions[];
    rrLookdownItem: rrLookdownItem;
    rrLookdownItems: rrLookdownItem[];
    updateLookdownItem: (item: rrLookdownItem, index: number) => void;
    index: number;
    deleteLookdownItem: (index: number) => void;
    currentFilter: Filter;
};

const RRLookdownList = (props: Props): ReactElement => {
    const { lang } = useLang();

    const handleFieldSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            props.updateLookdownItem(
                { ...props.rrLookdownItem, demographicFieldId: -1, demographicField: "" },
                props.index
            );
            return;
        }

        props.updateLookdownItem(
            { ...props.rrLookdownItem, demographicFieldId: value.id, demographicField: value.label },
            props.index
        );
    };

    const selectedDemographic = (id: number) => {
        const found = props.compareOptions.find(s => s.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.name
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    return (
        <Box display="flex">
            <Box width={416} pl={2}>
                <Autocomplete
                    id={`ss-export-rr-combo-box-${props.index}`}
                    options={props.compareOptions
                        .filter(option => option.id !== -1)
                        .map(s => {
                            return {
                                id: s.id,
                                label: s.name
                            };
                        })}
                    noOptionsText={lang.noOptionsAvailable}
                    placeholder={lang.demographics}
                    value={selectedDemographic(props.rrLookdownItem.demographicFieldId)}
                    getOptionDisabled={option =>
                        props.rrLookdownItems.some(item => item.demographicFieldId === option.id)
                    }
                    onChange={handleFieldSelected}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            </Box>
            <IconButton
                aria-label="delete"
                data-testid={`rrLookdown-delete-${props.index}`}
                onClick={(event): void => {
                    event.stopPropagation();
                    props.deleteLookdownItem(props.index);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};

export default RRLookdownList;
